import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import { initialState } from "./ai-assistant.types";

const aiAssistantSlice = createSlice({
  name: "AI Assistant",
  initialState,
  reducers: {
    openChatBox: (state) => {
      state.isOpenChatBox = true;
    },
    closeChatBox: (state) => {
      state.isOpenChatBox = false;
    },
    onResize: (state, action) => {
      state.chatBoxState = {
        ...state.chatBoxState,
        width: action.payload.width,
        height: action.payload.height,
        x: action.payload.x,
        y: action.payload.y
      };
    },
    onDragStop: (state, action) => {
      state.chatBoxState = {
        ...state.chatBoxState,
        x: action.payload.x,
        y: action.payload.y
      };
    },
    onChangeChat: (state, action) => {
      state.currentValue = action.payload;
    },
    onUpdateUserPrompt: (state, action) => {
      const { chatId, answer, prompt, user, version } = action.payload;

      state.formattedChatHistory = [
        ...state.formattedChatHistory,
        {
          id: uuidv4(),
          originalDate: dayjs().format("MMM DD"),
          chats: [
            {
              id: chatId,
              prompt,
              ai_response: answer,
              updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              user,
              version
            }
          ]
        }
      ];
    },
    onSendChat: (state, action) => {
      state.formattedChatHistory = [
        ...state.formattedChatHistory,
        {
          id: uuidv4(),
          originalDate: dayjs().format("MMM DD"),
          chats: [
            {
              id: action.payload.chatId,
              prompt: action.payload.prompt,
              ai_response: "",
              updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              user: action.payload.user,
              version: action.payload.version
            }
          ]
        }
      ];
    }
  },
  extraReducers: {}
});

export const {
  openChatBox,
  closeChatBox,
  onResize,
  onDragStop,
  onChangeChat,
  onSendChat,
  onUpdateUserPrompt
} = aiAssistantSlice.actions;

export default aiAssistantSlice.reducer;
