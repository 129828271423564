import {
  ICurrentUser,
  ICustomer,
  IUserGroup,
  IUserSubscription
} from "types/user.model";

export interface UserInitialState {
  loading: boolean;
  full_name: string;
  public_username: string | null;
  subscription: IUserSubscription;
  customerList: ICustomer[];
  userGroupList: IUserGroup[];
  currentUser: ICurrentUser | null;
}

export const userInitialState: UserInitialState = {
  loading: false,
  full_name: "",
  public_username: "",
  subscription: {
    name: "",
    is_free: true,
    unassigned: true,
    failed_by_payment: false,
    subscription_group: 0,
    valid_until: null
  },
  currentUser: null,
  customerList: [],
  userGroupList: []
};
