import {
  IAnalysis,
  ICreateAnalysisResponse,
  IAutocompleteResponse,
  IVersion,
  IAnalysisFirstStep,
  IAnalysisSecondStep,
  IAnalysisThirdStep,
  IIndustryAnalysis,
  IComparableCompanyData,
  IIndustryWithSwot,
  IDataSourceRatio,
  IPriceTarget,
  IKeyMetric,
  ILiveStockPrice,
  IAnalysisWorksheet
} from "types/analysis.model";
import { IVersionDataJson } from "types/version-data.model";
import { IDashboard, IChart, IDates } from "types/dashboard.model";
import { IChart as IChartTemplate } from "types/template.model";
import { IReportResponse } from "types/report.model";
import { IAddChildResponse } from "types/child-logic.model";
import { DEFAULT_INDUSTRY } from "modules/app/analysis/components/create-analysis-modal/create-analysis-modal.constants";

export interface IValue {
  annual?: number[];
  quarterly?: number[];
}

export interface ICalculationType {
  [key: string]: {
    value?: IValue;
    historical_type?: string;
    projection_type?: string;
  };
}

export interface ITrackDataPosition {
  system_index: string;
  positionIndex: string;
  period: "annual" | "quarterly";
}

export interface IAvailableChart {
  id: number;
  title: string;
}

export type IChartWithId = IChart & {
  id: number;
};

export interface InitialState {
  loading: boolean;
  loadingReport: boolean;
  loadingIndustry: boolean;
  loadingGetComparableData: boolean;
  loadingIndustryCountries: boolean;
  loadingKeyMetrics: boolean;
  loadingSwot: boolean;
  analyses: IAnalysis[];
  metadata: ICreateAnalysisResponse | null;
  versionData: IVersionDataJson;
  isExistDataMovePeriod: boolean;
  availableCharts: IAvailableChart[];
  analysisWorksheets: IAnalysisWorksheet[];
  dashboard: IDashboard;
  report: IReportResponse;
  stockSymbolList: IAutocompleteResponse[];
  currentVersion: IVersion | null;
  addChildResponse: IAddChildResponse | null;
  currentGroup: Record<
    string,
    {
      name: string;
      explanations: Record<string, string>;
    }
  >;
  analysisFirstStep: IAnalysisFirstStep;
  analysisSecondStep: IAnalysisSecondStep;
  analysisThirdStep: IAnalysisThirdStep;
  industryAnalysis: IIndustryAnalysis[];
  selectedCompanies: string[];
  comparableCompanyData: IComparableCompanyData[];
  financialDataYear: number[];
  industryCountries: string[];
  industryWithSwot: IIndustryWithSwot | null;
  dataSourceRatios: IDataSourceRatio[];
  currentStockSymbols: string[];
  priceTarget: IPriceTarget[];
  stockPrice: ILiveStockPrice[];
  keyMetrics: IKeyMetric[];
  piotroskiScores: any[];
  currentColumnId: string;
  currentDateTitle: string;
  aiContent: {
    companyProfile: string;
    companySwot: string;
    supplyChain: string;
    worksheetReport: { id: number; content: string }[];
    charts: {
      chart: IChartWithId;
      dates: IDates;
      report: number;
    }[];
  };
  isGenerating: boolean;
  isAiGenerated: boolean;
  isLeavingReport: boolean;
  selectedVersion: IVersion | null;
  calculatorTabIndex: number;
  analysisId: number | null;
  projectionConstant: string;
  projectionConstantValue: string;
  projectionConstantValueError: string;
  defaultCharts: IChartTemplate[];
}

export const initialState: InitialState = {
  loading: true,
  loadingReport: false,
  loadingIndustry: false,
  loadingGetComparableData: false,
  loadingIndustryCountries: false,
  loadingKeyMetrics: false,
  loadingSwot: false,
  industryCountries: [],
  analyses: [],
  metadata: null,
  currentGroup: {},
  addChildResponse: null,
  isExistDataMovePeriod: true,
  versionData: {
    id: 0,
    name: "",
    version_meta_data: {
      selected_periods: [],
      historical_years: 0,
      projection_years: 0,
      historical_quarters: 0,
      projection_quarters: 0,
      date_titles: [],
      position_indexes: {
        annual: [],
        quarterly: []
      },
      fields: {},
      fields_dependencies: {},
      interval: "default",
      worksheet_intervals: [],
      overwritten_fields_values: []
    },
    calculated_version: {}
  },
  availableCharts: [],
  analysisWorksheets: [],
  dashboard: {
    ratio_groups: [],
    dates: {
      annual: []
    },
    dashboard_charts: []
  },
  report: {
    report: {
      title: "",
      content: "",
      selected_chart_ids: [],
      worksheets: [],
      is_company_profile: false,
      is_company_swot: false,
      id: 0
    },
    dashboard_data: {
      ratio_groups: [],
      dates: {
        annual: []
      },
      dashboard_charts: []
    }
  },
  stockSymbolList: [],
  currentVersion: null,
  analysisFirstStep: {
    name: "",
    data_source: "none",
    stock_symbol: null,
    defaultVersionName: "",
    maxHistoricalYearsAvailable: null,
    currency: "",
    industries: [],
    predefinedIndustry: null
  },
  analysisSecondStep: {
    historicalYears: null,
    projectionYears: null,
    firstProjectionQuarter: null,
    firstProjectionYear: 0,
    unit: 1,
    isQuarters: false,
    currency: "",
    selectedPeriods: [],
    industry: DEFAULT_INDUSTRY,
    language: "",
    availableTemplates: []
  },
  analysisThirdStep: {
    template: ""
  },
  industryAnalysis: [],
  selectedCompanies: [],
  comparableCompanyData: [],
  financialDataYear: [],
  industryWithSwot: null,
  dataSourceRatios: [],
  currentStockSymbols: [],
  priceTarget: [],
  stockPrice: [],
  keyMetrics: [],
  piotroskiScores: [],
  currentColumnId: "",
  currentDateTitle: "",
  aiContent: {
    companyProfile: "",
    supplyChain: "",
    companySwot: "",
    worksheetReport: [],
    charts: []
  },
  isGenerating: false,
  isAiGenerated: false,
  isLeavingReport: false,
  selectedVersion: null,
  calculatorTabIndex: 0,
  analysisId: null,
  projectionConstant: "average",
  projectionConstantValue: "",
  projectionConstantValueError: "",
  defaultCharts: []
};
