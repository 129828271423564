import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ICreateAnalysisResponse,
  IAutocompleteResponse,
  IDuplicateAnalysisResponse,
  IAnalysisFirstStep,
  IAnalysisSecondStep,
  IAnalysisThirdStep,
  IIndustryAnalysis,
  IComparableCompanyData,
  IIndustryCountry,
  IIndustryWithSwot,
  IPriceTarget,
  IDataSourceRatio,
  IKeyMetric,
  IPiotroskiScore,
  ILiveStockPrice,
  IAnalysisWorksheet
} from "types/analysis.model";
import {
  IVersionDataJson,
  IVersionDataField,
  IMovePeriodResponse,
  ICalculatedVersion
} from "types/version-data.model";
import { IChart, IDashboard, IDates } from "types/dashboard.model";
import {
  IChart as IChartTemplate,
  ITemplateResponse
} from "types/template.model";
import { IReportResponse } from "types/report.model";
import { METADATA_JSON } from "constants/config";
import { calculateNumber, isEmpty } from "helpers/lib";
import { generateSubIndex } from "modules/app/calculator/statements/statements.helpers";
import { getMedianValue } from "helpers";
import { cloneDeep } from "lodash";
import { changeLogicGroup } from "../helpers";
import {
  getAvailableCharts,
  getComparableData,
  getCustomerAnalysisData,
  getCustomerDashboardData,
  getExistingComparableData,
  getVersionData,
  moveProjection,
  createChart,
  getReport,
  deleteChart,
  getStockSymbols,
  duplicateAnalysis,
  getIndustryCompanyAnalysis,
  getIndustryCountries,
  getSwot,
  getSwotTranslated,
  getDataSourceRatio,
  getPriceTarget,
  getLiveStockPrice,
  getKeyMetrics,
  getPiotroskiScore
} from "./analysis.services";
import {
  initialState,
  IValue,
  ICalculationType,
  ITrackDataPosition,
  IAvailableChart
} from "./analysis.types";
import { getNewValues } from "./analysis.helpers";

const analysesSlice = createSlice({
  name: "analyses",
  initialState,
  reducers: {
    setMetadata: (state, action) => {
      state.metadata = { ...action.payload };
    },
    // set new version data after calculate
    onClickCalculate: (state, action) => {
      state.versionData = {
        ...state.versionData,
        calculated_version: action.payload.calculated_version
      };

      state.currentVersion = {
        id: action.payload.id,
        name: action.payload.name || state.currentVersion?.name
      };

      if (state.metadata && action.payload.name && action.payload.id) {
        const versionList = [...state.metadata.versions];

        versionList.unshift({
          id: action.payload.id,
          name: action.payload.name
        });

        state.metadata = {
          ...state.metadata,
          versions: versionList
        };

        sessionStorage.setItem(METADATA_JSON, JSON.stringify(state.metadata));
      }
    },

    getAddChildResponse: (state, action) => {
      state.addChildResponse = action.payload;
    },
    updateVersionName: (state, action) => {
      if (state.metadata && action.payload.name && action.payload.id) {
        const versionList = state.metadata.versions.map((version) => {
          if (version.id === action.payload.id) {
            return {
              ...version,
              name: action.payload.name
            };
          }
          return version;
        });

        state.metadata = {
          ...state.metadata,
          versions: versionList
        };

        sessionStorage.setItem(METADATA_JSON, JSON.stringify(state.metadata));
      }
    },

    // handle table row
    onAddChildRow: (state, action) => {
      const { childLogic, rowData, group } = action.payload;

      const { parent_config, plug_config, children_config } = childLogic;

      const systemIndex = rowData.original.system_index;
      const childBaseSystemIndex = rowData.original.child;
      const childRows = rowData.original.subRows.filter(
        (sub: any) =>
          sub.system_index.includes("child") &&
          !sub.system_index.includes("helper")
      );
      const helperRows = rowData.original.subRows.filter(
        (sub: any) =>
          !sub.system_index.includes("child") &&
          sub.system_index.includes("helper")
      );
      const childHelperRows = rowData.original.subRows.filter((sub: any) =>
        sub.system_index.includes("child_helper")
      );

      const lastHelperOrderNumber =
        helperRows.length === 0
          ? 1
          : helperRows[helperRows.length - 1].order_number + 1;

      const childSystemIndex: string =
        generateSubIndex(childRows) > 0
          ? `${childBaseSystemIndex}_${generateSubIndex(childRows)}`
          : childBaseSystemIndex;

      const plugRowSystemIndex = `${systemIndex}_plug`;

      const value: IValue = {
        annual: Array(
          state.versionData.calculated_version[systemIndex].value.annual?.length
        ).fill(0),
        quarterly: Array(
          state.versionData.calculated_version[systemIndex].value.quarterly
            ?.length
        ).fill(0)
      };

      if (!state.versionData.calculated_version[systemIndex].value.quarterly) {
        delete value.quarterly;
      }

      let helperFields: ICalculationType = {};
      let helperFieldsTitles: any = {};
      let childHelperFields: ICalculationType = {};
      let childHelperFieldTitles: any = {};
      let childHelperFieldDependencies: any = {};
      let plugRow: any = {};

      if (parent_config.parent_helper_default_types) {
        Object.entries(parent_config.parent_helper_default_types).forEach(
          ([helper, helperValues]: any, index) => {
            helperFields = {
              ...helperFields,
              [helper]: {
                value
              }
            };

            helperFieldsTitles = {
              ...helperFieldsTitles,
              [helper]: {
                title: `${parent_config.parent_helper_titles[helper]}`,
                order_number: index + 1,
                styles: ["indent"],
                format:
                  parent_config.parent_helper_default_formats?.[helper]?.[
                    group
                  ] ?? "number",
                historical_type: helperValues.historical,
                projection_type: helperValues.projection
              }
            };
          }
        );
      }

      const lastOrderNumber =
        rowData.original?.subRows.length === 1 ||
        !rowData.original?.subRows.some((row: any) =>
          row.system_index.includes("plug")
        )
          ? rowData.original?.subRows[rowData.original.subRows.length - 1]
              ?.order_number
          : rowData.original?.subRows[rowData.original.subRows.length - 2]
              ?.order_number;

      if (children_config.child_helper_default_types) {
        const childTitles = childLogic.children_config.child_helper_titles;

        Object.entries(children_config.child_helper_default_types).forEach(
          ([helper, helperValues]: any, index) => {
            const childHelperTitle = `${childTitles[helper]} ${`${
              generateSubIndex(childRows) + 1
            }`}`;

            const helperSystemIndex =
              generateSubIndex(childHelperRows) > 0
                ? `${helper}_${generateSubIndex(childHelperRows)}`
                : helper;

            childHelperFields = {
              ...childHelperFields,
              [helperSystemIndex]: {
                value
              }
            };

            childHelperFieldTitles = {
              ...childHelperFieldTitles,
              [helperSystemIndex]: {
                title: childHelperTitle,
                order_number: lastOrderNumber
                  ? index + lastOrderNumber + 2
                  : index + lastHelperOrderNumber + 2,
                styles: ["indent"],
                format:
                  children_config.child_helper_default_formats?.[helper]?.[
                    group
                  ] ?? "number",
                historical_type: helperValues.historical,
                projection_type: helperValues.projection
              }
            };

            childHelperFieldDependencies = {
              ...childHelperFieldDependencies,
              [helper]: `${helperSystemIndex}`
            };
          }
        );
      }

      if (plug_config.plug_default_types) {
        plugRow = {
          [plugRowSystemIndex]: {
            value
          }
        };
      }

      const fieldsDependenciesChildren =
        state.versionData.version_meta_data.fields_dependencies[systemIndex]
          ?.children?.[childBaseSystemIndex] || [];

      const fieldsDependencies: any = {
        ...state.versionData.version_meta_data.fields_dependencies,
        [systemIndex]: {
          ...state.versionData.version_meta_data.fields_dependencies[
            systemIndex
          ],
          children: {
            [childBaseSystemIndex]: [
              ...fieldsDependenciesChildren,
              childSystemIndex
            ]
          },
          plug: plugRowSystemIndex
        },
        [childSystemIndex]: {
          helpers: childHelperFieldDependencies
        }
      };

      const fields: any = {
        ...state.versionData.version_meta_data.fields,
        [systemIndex]: {
          ...state.versionData.version_meta_data.fields[systemIndex],
          historical_type: parent_config.parent_default_types.historical,
          projection_type: parent_config.parent_default_types.projection
        }
      };

      const calculatedVersion = {
        ...state.versionData.calculated_version,
        [systemIndex]: {
          ...state.versionData.calculated_version[systemIndex]
        },
        ...helperFields,
        [childSystemIndex]: {
          value
        },
        ...childHelperFields,
        ...plugRow
      };

      if (
        parent_config.parent_helper_required &&
        parent_config.parent_default_types
      ) {
        Object.keys(fields).forEach((key) => {
          if (
            key.includes("helper") &&
            !key.includes("child") &&
            key.includes(`${systemIndex}_helper`) &&
            isEmpty(helperFieldsTitles[key])
          ) {
            delete fields[key];
            delete calculatedVersion[key];
          }
        });
        Object.assign(fields, helperFieldsTitles);
      }

      let childRowOrderNumber = 0;
      if (parent_config.parent_helper_default_types) {
        childRowOrderNumber = Object.keys(
          parent_config.parent_helper_default_types
        ).length;
      }

      if (children_config.child_default_types) {
        const childTitle = `${childLogic.children_config.child_title} ${
          generateSubIndex(childRows) + 1
        }`;

        Object.assign(fields, {
          [childSystemIndex]: {
            title: childTitle,
            order_number: lastOrderNumber
              ? lastOrderNumber + 1
              : childRowOrderNumber + 1,
            styles: [],
            format: children_config.child_default_formats?.[group] ?? "number",
            historical_type: children_config.child_default_types.historical,
            projection_type: children_config.child_default_types.projection
          }
        });
      }

      if (
        children_config.child_helper_default_types &&
        children_config.child_helper_required
      ) {
        Object.assign(fields, childHelperFieldTitles);
      }

      if (plug_config.is_plug_required && plug_config.plug_default_types) {
        Object.assign(fields, {
          [plugRowSystemIndex]: {
            title: "Plug",
            order_number: Object.keys(fields).length + 1,
            styles: [],
            format: "number",
            historical_type: plug_config.plug_default_types.historical,
            projection_type: plug_config.plug_default_types.projection
          }
        });
      }

      if (!plug_config.plug_default_types || !plug_config.is_plug_required) {
        delete fieldsDependencies[systemIndex].plug;
        delete fields[plugRowSystemIndex];
      }

      if (
        !parent_config.parent_helper_required &&
        !parent_config.parent_helper_default_types
      ) {
        Object.entries(calculatedVersion).forEach(([key, values]: any) => {
          if (
            key.includes("helper") &&
            !key.includes("child") &&
            key.includes(`${systemIndex}_helper`)
          ) {
            delete calculatedVersion[key];
          }
        });

        Object.entries(fields).forEach(([key, values]: any) => {
          if (
            key.includes("helper") &&
            !key.includes("child") &&
            key.includes(`${systemIndex}_helper`)
          ) {
            delete fields[key];
          }
        });

        delete fieldsDependencies[systemIndex].helpers;
      }

      state.versionData = {
        ...state.versionData,
        calculated_version: calculatedVersion,
        version_meta_data: {
          ...state.versionData.version_meta_data,
          fields_dependencies: fieldsDependencies,
          fields
        }
      };
    },
    onDeleteChildRow: (state, action) => {
      const { childRowSystemIndex, parentRowData, childLogic } = action.payload;

      const parentRowSystemIndex: string = parentRowData.original?.system_index;
      const currentVersionData: IVersionDataJson = { ...state.versionData };

      const value: any = {
        annual: Array(
          state.versionData.calculated_version[parentRowSystemIndex].value
            .annual?.length
        ).fill(0),
        quarterly: Array(
          state.versionData.calculated_version[parentRowSystemIndex].value
            .quarterly?.length
        ).fill(0)
      };

      if (
        !state.versionData.calculated_version[parentRowSystemIndex].value
          .quarterly
      ) {
        delete value.quarterly;
      }

      const currentChildRows =
        currentVersionData.version_meta_data.fields_dependencies[
          parentRowSystemIndex
        ].children?.[`${parentRowSystemIndex}_child`] || [];

      const currentChildHelpers =
        currentVersionData.version_meta_data.fields_dependencies[
          childRowSystemIndex
        ].helpers || {};

      const currentChildIndex = currentChildRows.indexOf(childRowSystemIndex);
      currentChildRows.splice(currentChildIndex, 1);

      if (currentChildRows.length === 0) {
        delete currentVersionData.version_meta_data.fields_dependencies[
          parentRowSystemIndex
        ].children;
      }

      for (const item in currentVersionData.calculated_version) {
        if (item) {
          if (Object.values(currentChildHelpers).includes(item)) {
            delete currentVersionData.calculated_version[item];
            delete currentVersionData.version_meta_data.fields[item];
          }

          if (childLogic && item.includes(`${parentRowSystemIndex}_plug`)) {
            delete currentVersionData.calculated_version[item];
            delete currentVersionData.version_meta_data.fields_dependencies[
              parentRowSystemIndex
            ].plug;
            delete currentVersionData.version_meta_data.fields[item];
          }
        }
      }

      if (
        childLogic &&
        !childLogic.parent_config.parent_helper_default_types &&
        !childLogic.parent_config.parent_helper_required
      ) {
        for (const key in currentVersionData.version_meta_data.fields) {
          if (
            key.includes("helper") &&
            !key.includes("child") &&
            key.includes(`${parentRowSystemIndex}_helper`)
          ) {
            delete currentVersionData.version_meta_data.fields[key];
            delete currentVersionData.calculated_version[key];
          }
        }

        delete currentVersionData.version_meta_data.fields_dependencies[
          parentRowSystemIndex
        ].helpers;
      }

      if (childLogic && childLogic.parent_config.parent_helper_default_types) {
        const newHelperSystemIndexes = Object.keys(
          childLogic.parent_config.parent_helper_default_types
        );

        Object.keys(currentVersionData.version_meta_data.fields).forEach(
          (key) => {
            if (
              key.includes("helper") &&
              !key.includes("child") &&
              !newHelperSystemIndexes.includes(key) &&
              key.includes(`${parentRowSystemIndex}_helper`)
            ) {
              delete currentVersionData.version_meta_data.fields[key];
              delete currentVersionData.calculated_version[key];
            }
          }
        );

        Object.entries(
          childLogic.parent_config.parent_helper_default_types
        ).forEach(([helperSystemIndex, helperValues]: any, index) => {
          const splittedHelperSystemIndex = helperSystemIndex.split("_");

          const helperIndex = Number.isNaN(
            parseFloat(
              splittedHelperSystemIndex[splittedHelperSystemIndex.length - 1]
            )
          )
            ? 1
            : Number(
                splittedHelperSystemIndex[splittedHelperSystemIndex.length - 1]
              );

          currentVersionData.version_meta_data.fields = {
            ...currentVersionData.version_meta_data.fields,
            [helperSystemIndex]: {
              title: `${
                childLogic.parent_config.parent_helper_titles[helperSystemIndex]
              } ${
                Object.keys(
                  childLogic.parent_config.parent_helper_default_types
                ).length === 1
                  ? 1
                  : helperIndex
              }`,
              order_number: index + 1,
              styles: ["indent"],
              format: "number",
              historical_type: helperValues.historical,
              projection_type: helperValues.projection
            }
          };

          currentVersionData.calculated_version = {
            ...currentVersionData.calculated_version,
            [helperSystemIndex]: {
              value
            }
          };

          currentVersionData.version_meta_data.fields_dependencies = {
            ...currentVersionData.version_meta_data.fields_dependencies,
            [parentRowSystemIndex]: {
              ...currentVersionData.version_meta_data.fields_dependencies[
                parentRowSystemIndex
              ],
              helpers: {
                ...currentVersionData.version_meta_data.fields_dependencies[
                  parentRowSystemIndex
                ].helpers,
                [helperSystemIndex]: helperSystemIndex
              }
            }
          };
        });
      }

      if (childLogic) {
        currentVersionData.version_meta_data.fields[parentRowSystemIndex] = {
          ...currentVersionData.version_meta_data.fields[parentRowSystemIndex],
          historical_type:
            childLogic.parent_config.parent_default_types.historical,
          projection_type:
            childLogic.parent_config.parent_default_types.projection
        };
      }

      delete currentVersionData.calculated_version[childRowSystemIndex];
      delete currentVersionData.version_meta_data.fields[childRowSystemIndex];
      delete currentVersionData.version_meta_data.fields_dependencies[
        childRowSystemIndex
      ];

      state.versionData = currentVersionData;
    },
    onFinishEditCell: (
      state,
      action: PayloadAction<{
        trackDataPosition: ITrackDataPosition;
        value: string;
        unit: number;
        format: string;
      }>
    ) => {
      const { trackDataPosition, value, unit, format } = action.payload;
      let correctValue = "";

      if (trackDataPosition.positionIndex !== "title") {
        if (!Number.isNaN(Number(value.trim()))) {
          if (format === "percentage") {
            correctValue = trackDataPosition.system_index.includes("helper")
              ? String(Number(value.trim()) / 100)
              : String(
                  calculateNumber(
                    "multiply",
                    String(Number(value.trim()) / 100),
                    unit
                  )
                );
          } else {
            correctValue = trackDataPosition.system_index.includes("helper")
              ? value.trim()
              : String(calculateNumber("multiply", value.trim(), unit));
          }
        }

        const indexOfData =
          state.versionData.version_meta_data.position_indexes[
            trackDataPosition.period
          ].indexOf(Number(trackDataPosition.positionIndex));
        const periodDataArray =
          state.versionData.calculated_version[trackDataPosition.system_index]
            .value[trackDataPosition.period];
        const clonePeriodDataArray = [...periodDataArray];
        clonePeriodDataArray[indexOfData] = Number(correctValue);

        state.versionData = {
          ...state.versionData,
          calculated_version: {
            ...state.versionData.calculated_version,
            [trackDataPosition.system_index]: {
              ...state.versionData.calculated_version[
                trackDataPosition.system_index
              ],
              value: {
                ...state.versionData.calculated_version[
                  trackDataPosition.system_index
                ].value,
                [trackDataPosition.period]: clonePeriodDataArray
              }
            }
          }
        };
      } else {
        state.versionData = {
          ...state.versionData,
          version_meta_data: {
            ...state.versionData.version_meta_data,
            fields: {
              ...state.versionData.version_meta_data.fields,
              [trackDataPosition.system_index]: {
                ...state.versionData.version_meta_data.fields[
                  trackDataPosition.system_index
                ],
                title:
                  value.trim() ||
                  state.versionData.version_meta_data.fields[
                    trackDataPosition.system_index
                  ].title
              }
            }
          }
        };
      }
    },
    onAddProjectionColumn: (state, action) => {
      const isQuarter =
        state.versionData.version_meta_data.selected_periods.includes(
          "quarterly"
        );

      if (!isQuarter) {
        state.versionData.version_meta_data.date_titles = [
          ...state.versionData.version_meta_data.date_titles,
          ...action.payload
        ];
        state.versionData.version_meta_data.position_indexes.annual = [
          ...state.versionData.version_meta_data.position_indexes.annual,
          state.versionData.version_meta_data.position_indexes.annual.length
        ];
        state.versionData.version_meta_data.projection_years += 1;
        Object.keys(state.versionData.calculated_version).forEach(
          (key) =>
            (state.versionData.calculated_version[key] = {
              ...state.versionData.calculated_version[key],
              value: {
                ...state.versionData.calculated_version[key].value,
                annual: [
                  ...state.versionData.calculated_version[key].value.annual,
                  0
                ]
              }
            })
        );
      } else {
        state.versionData.version_meta_data.date_titles = [
          ...state.versionData.version_meta_data.date_titles,
          ...action.payload
        ];
        state.versionData.version_meta_data.position_indexes.annual = [
          ...state.versionData.version_meta_data.position_indexes.annual,
          state.versionData.version_meta_data.position_indexes.annual[
            state.versionData.version_meta_data.position_indexes.annual.length -
              1
          ] + 5
        ];
        const lastQuarterlyIndex =
          state.versionData.version_meta_data.position_indexes.quarterly[
            state.versionData.version_meta_data.position_indexes.quarterly
              .length - 1
          ];
        state.versionData.version_meta_data.position_indexes.quarterly = [
          ...state.versionData.version_meta_data.position_indexes.quarterly,
          lastQuarterlyIndex + 2,
          lastQuarterlyIndex + 3,
          lastQuarterlyIndex + 4,
          lastQuarterlyIndex + 5
        ];
        state.versionData.version_meta_data.projection_years += 1;
        state.versionData.version_meta_data.projection_quarters += 4;
        Object.keys(state.versionData.calculated_version).forEach(
          (key) =>
            (state.versionData.calculated_version[key] = {
              ...state.versionData.calculated_version[key],
              value: {
                ...state.versionData.calculated_version[key].value,
                annual: [
                  ...state.versionData.calculated_version[key].value.annual,
                  0
                ],
                quarterly: [
                  ...state.versionData.calculated_version[key].value.quarterly,
                  0,
                  0,
                  0,
                  0
                ]
              }
            })
        );
      }
    },
    onFinishEditCellForEmptyHistorical: (state, action) => {
      const { system_index, position_index, value, period, unit } =
        action.payload;

      const overWrittenFieldsValues =
        state.versionData.version_meta_data.overwritten_fields_values.some(
          (item) => item.system_index === system_index
        )
          ? state.versionData.version_meta_data.overwritten_fields_values.map(
              (item) =>
                item.system_index === system_index
                  ? {
                      ...item,
                      position_index,
                      value: system_index.includes("helper")
                        ? Number(value.trim()) / 100
                        : calculateNumber("multiply", value.trim(), unit),
                      period
                    }
                  : item
            )
          : [
              ...state.versionData.version_meta_data.overwritten_fields_values,
              {
                system_index,
                position_index: Number(position_index),
                value: system_index.includes("helper")
                  ? Number(value.trim()) / 100
                  : calculateNumber("multiply", value.trim(), unit),
                period
              }
            ];

      state.versionData = {
        ...state.versionData,
        version_meta_data: {
          ...state.versionData.version_meta_data,
          overwritten_fields_values: overWrittenFieldsValues
        }
      };

      // state.overwrittenFields = state.overwrittenFields.some(
      //   (item) => item.system_index === system_index
      // )
      //   ? state.overwrittenFields.map((item) =>
      //       item.system_index === system_index
      //         ? {
      //             ...item,
      //             period,
      //             position_index,
      //             value: system_index.includes("helper")
      //               ? Number(value.trim()) / 100
      //               : calculateNumber("multiply", value.trim(), unit)
      //           }
      //         : item
      //     )
      //   : [
      //       ...state.overwrittenFields,
      //       {
      //         system_index,
      //         position_index: Number(position_index),
      //         value: system_index.includes("helper")
      //           ? Number(value.trim()) / 100
      //           : calculateNumber("multiply", value.trim(), unit),
      //         period
      //       }
      //     ];
    },
    onAddRowStyling: (state, action) => {
      const rowStyles = [];
      if (action.payload.bold) {
        rowStyles.push("bold");
      }
      if (action.payload.indent) {
        rowStyles.push("indent");
      }
      if (action.payload.backgroundColor) {
        rowStyles.push("backgroundColor");
      }

      state.versionData = {
        ...state.versionData,
        version_meta_data: {
          ...state.versionData.version_meta_data,
          fields: {
            ...state.versionData.version_meta_data.fields,
            [action.payload.systemIndex]: {
              ...state.versionData.version_meta_data.fields[
                action.payload.systemIndex
              ],
              styles: rowStyles,
              format: action.payload.format
            }
          }
        }
      };
    },
    onChangeLogicGroup: (state, action) => {
      const { rowData, childLogic, group } = action.payload;

      const parentRowSystemIndex: string = rowData.original?.system_index;

      state.versionData = changeLogicGroup(
        state.versionData,
        childLogic,
        parentRowSystemIndex,
        group
      );
    },
    // confirm moving projection column if there is no data
    onConfirmMoveProjectionColumn: (state, action) => {
      const { dateTitle, positionIndex } = action.payload;

      const isQuarter =
        state.versionData.version_meta_data.selected_periods.includes(
          "quarterly"
        );
      const currentComparableIndexes = state.versionData.version_meta_data.extra
        ?.comparable_indexes || {
        annual: [],
        quarterly: []
      };

      const currentExtra = state.versionData.version_meta_data.extra || {};

      if (isQuarter) {
        const annualComparableIndexes = dateTitle.includes("Q4")
          ? [...currentComparableIndexes.annual, Number(positionIndex) + 1]
          : [...currentComparableIndexes.annual];
        const quarterComparableIndexes = dateTitle.includes("Q")
          ? [...currentComparableIndexes.quarterly, Number(positionIndex)]
          : [...currentComparableIndexes.quarterly];

        state.versionData = {
          ...state.versionData,
          version_meta_data: {
            ...state.versionData.version_meta_data,
            historical_quarters: Number(
              state.versionData.version_meta_data.historical_quarters + 1
            ),
            projection_quarters: Number(
              state.versionData.version_meta_data.projection_quarters - 1
            ),
            historical_years: dateTitle.includes("Q4")
              ? Number(state.versionData.version_meta_data.historical_years + 1)
              : state.versionData.version_meta_data.historical_years,
            projection_years: dateTitle.includes("Q4")
              ? Number(state.versionData.version_meta_data.projection_years - 1)
              : state.versionData.version_meta_data.projection_years,
            extra: {
              ...currentExtra,
              comparable_indexes: {
                ...currentComparableIndexes,
                annual: annualComparableIndexes,
                quarterly: quarterComparableIndexes
              }
            }
          }
        };
      } else {
        state.versionData = {
          ...state.versionData,
          version_meta_data: {
            ...state.versionData.version_meta_data,
            historical_years: Number(
              state.versionData.version_meta_data.historical_years + 1
            ),
            projection_years: Number(
              state.versionData.version_meta_data.projection_years - 1
            ),
            extra: {
              ...currentExtra,
              comparable_indexes: {
                ...currentComparableIndexes,
                annual: [
                  ...currentComparableIndexes.annual,
                  Number(positionIndex)
                ],
                quarterly: []
              }
            }
          }
        };
      }

      Object.keys(state.versionData.calculated_version).forEach(
        (key: string) => {
          if (isQuarter) {
            state.versionData.calculated_version = {
              ...state.versionData.calculated_version,
              [key]: {
                ...state.versionData.calculated_version[key],
                value: {
                  ...state.versionData.calculated_version[key].value,
                  quarterly: [
                    ...state.versionData.calculated_version[key].value
                      .quarterly,
                    0
                  ],
                  expectedQuarterly: [
                    ...state.versionData.calculated_version[key].value
                      .quarterly,
                    0
                  ],
                  annual: dateTitle.includes("Q4")
                    ? Array(
                        state.versionData.calculated_version[key].value.annual
                          .length
                      ).fill(0)
                    : state.versionData.calculated_version[key].value.annual,
                  expectedAnnual: dateTitle.includes("Q4")
                    ? Array(
                        state.versionData.calculated_version[key].value.annual
                          .length
                      ).fill(0)
                    : state.versionData.calculated_version[key].value.annual
                }
              }
            };
          } else {
            state.versionData.calculated_version = {
              ...state.versionData.calculated_version,
              [key]: {
                ...state.versionData.calculated_version[key],
                value: {
                  ...state.versionData.calculated_version[key].value,
                  annual: [
                    ...state.versionData.calculated_version[key].value.annual,
                    0
                  ],
                  expectedAnnual: [
                    ...state.versionData.calculated_version[key].value.annual,
                    0
                  ]
                }
              }
            };
          }
        }
      );
    },
    // cancel moving projection column if there is no data
    onCancelMoveProjectionColumn: (state) => {
      state.isExistDataMovePeriod = true;
    },
    onSaveOpenedCompareColumn: (state, action) => {
      const { columns } = action.payload;

      const currentOpenedCompareColumnAnnual = columns
        .filter((column: any) => column.accessor.includes("annual_variance"))
        .map((column: any) => Number(column.accessor.split("_")[2]));
      const currentOpenedCompareColumnQuarterly = columns
        .filter((column: any) => column.accessor.includes("quarterly_variance"))
        .map((column: any) => Number(column.accessor.split("_")[2]));

      const currentExtra = state.versionData.version_meta_data.extra;

      state.versionData = {
        ...state.versionData,
        version_meta_data: {
          ...state.versionData.version_meta_data,
          extra: {
            ...currentExtra,
            opened_comparable_indexes: {
              annual: currentOpenedCompareColumnAnnual,
              quarterly: currentOpenedCompareColumnQuarterly
            }
          }
        }
      };
    },

    // version
    onSelectVersion: (state, action) => {
      state.currentVersion = action.payload;
    },
    onDeleteVersion: (state, action) => {
      if (state.metadata) {
        const filteredVersionList = [...state.metadata.versions].filter(
          (version) => version.id !== action.payload
        );
        state.metadata = {
          ...state.metadata,
          versions: filteredVersionList
        };
        sessionStorage.setItem(METADATA_JSON, JSON.stringify(state.metadata));
      }
    },

    // handle creating analysis modal
    getAnalysisDataFirstStep: (
      state,
      action: PayloadAction<IAnalysisFirstStep>
    ) => {
      state.analysisFirstStep = { ...action.payload };
    },
    getAnalysisDataSecondStep: (
      state,
      action: PayloadAction<IAnalysisSecondStep>
    ) => {
      state.analysisSecondStep = { ...action.payload };
    },
    getAnalysisDataThirdStep: (
      state,
      action: PayloadAction<IAnalysisThirdStep>
    ) => {
      state.analysisThirdStep = { ...action.payload };
    },

    // group logic
    getCurrentGroup: (state, action) => {
      state.currentGroup = { ...state.currentGroup, ...action.payload };
      const systemIndex = Object.keys(action.payload)[0];

      state.versionData = {
        ...state.versionData,
        calculated_version: {
          ...state.versionData.calculated_version,
          [systemIndex]: {
            ...state.versionData.calculated_version[systemIndex],
            logics_group: action.payload[systemIndex].name
          }
        }
      };
    },

    // interval
    selectInterval: (state, action) => {
      const { interval, worksheetId } = action.payload;
      if (!state.metadata) return;

      const versionMetadata = state.versionData.version_meta_data;
      const currentIntervalIndex =
        versionMetadata.worksheet_intervals.findIndex(
          (item: any) => item.id === worksheetId
        );

      if (currentIntervalIndex > -1) {
        state.versionData.version_meta_data.worksheet_intervals[
          currentIntervalIndex
        ] = {
          id: worksheetId,
          value: interval
        };
      } else {
        state.versionData.version_meta_data = {
          ...versionMetadata,
          worksheet_intervals: [
            ...versionMetadata.worksheet_intervals,
            {
              id: worksheetId,
              value: interval
            }
          ]
        };
      }
    },

    selectCompany: (state, action) => {
      const { selectedCompany, checked } = action.payload;
      if (checked) {
        state.selectedCompanies = [...state.selectedCompanies, selectedCompany];
      } else {
        state.selectedCompanies = state.selectedCompanies.filter(
          (item) => item !== selectedCompany
        );
      }
    },

    getCurrentColumnId: (state, action) => {
      state.currentColumnId = action.payload;
    },
    getCurrentDateTitle: (state, action) => {
      state.currentColumnId = action.payload;
    },

    setAiContentCompanyProfile: (state, action) => {
      state.aiContent.companyProfile = action.payload;
    },
    setAiContentCompanySwot: (state, action) => {
      state.aiContent.companySwot = action.payload;
    },
    setAiContentSupplyChain: (state, action) => {
      state.aiContent.supplyChain = action.payload;
    },
    setAiContentWorksheetReport: (state, action) => {
      state.aiContent.worksheetReport = [
        ...state.aiContent.worksheetReport,
        action.payload
      ];
    },
    setAiContentCharts: (state, action) => {
      state.aiContent.charts = [...state.aiContent.charts, action.payload];
    },
    setIsAiGenerated: (state, action) => {
      state.isAiGenerated = action.payload;
    },
    setIsGenerating: (state, action) => {
      state.isGenerating = action.payload;
    },
    setCurrentReport: (state, action) => {
      state.report = action.payload;
    },
    setIsLeavingReport: (state, action) => {
      state.isLeavingReport = action.payload;
    },
    setSelectedVersion: (state, action) => {
      state.selectedVersion = action.payload;
    },
    setCalculatorTabIndex: (state, action) => {
      state.calculatorTabIndex = action.payload;
      state.selectedVersion = null;
    },
    setCurrentAnalysisId: (state, action) => {
      state.analysisId = action.payload;
    },

    // projection constant
    setProjectionConstant: (state, action) => {
      state.projectionConstant = action.payload;
    },
    onChangeProjectConstantValue: (state, action) => {
      state.projectionConstantValue = action.payload;
    },
    onSubmitProjectionConstant: (state, action) => {
      const { projectionConstant, versionData } = state;
      const { value, systemIndex } = action.payload;

      state.projectionConstantValueError = "";
      const isAnnualAnalysis =
        !versionData.version_meta_data.selected_periods.includes("quarterly");

      const currentRowAllValues =
        versionData.calculated_version[systemIndex].value;
      const currentRowHistoricalType =
        versionData.version_meta_data.fields[systemIndex].historical_type;
      const currentRowProjectionType =
        versionData.version_meta_data.fields[systemIndex].projection_type;
      const currentFormat =
        versionData.version_meta_data.fields[systemIndex].format;

      if (currentRowProjectionType === "input") {
        if (isAnnualAnalysis) {
          const numberOfHistoricalYears =
            versionData.version_meta_data.historical_years;
          const rowValues = currentRowAllValues.annual.slice(
            0,
            numberOfHistoricalYears
          );
          const projectionValues = currentRowAllValues.annual.slice(
            numberOfHistoricalYears,
            currentRowAllValues.annual.length
          );

          const growthValues =
            currentRowHistoricalType === "growth"
              ? rowValues.slice(1, rowValues.length)
              : rowValues;

          if (projectionConstant === "average") {
            const averageValue =
              growthValues.reduce((a, b) => a + b, 0) / growthValues.length;

            const newValues = getNewValues(
              value,
              averageValue,
              projectionValues,
              numberOfHistoricalYears,
              state.versionData.calculated_version[systemIndex].value.annual,
              currentFormat
            );

            state.versionData = {
              ...state.versionData,
              calculated_version: {
                ...state.versionData.calculated_version,
                [systemIndex]: {
                  ...state.versionData.calculated_version[systemIndex],
                  value: {
                    ...state.versionData.calculated_version[systemIndex].value,
                    annual: newValues
                  }
                }
              }
            };
          }

          if (projectionConstant === "median") {
            const medianValue = getMedianValue(growthValues);

            const newValues = getNewValues(
              value,
              medianValue,
              projectionValues,
              numberOfHistoricalYears,
              state.versionData.calculated_version[systemIndex].value.annual,
              currentFormat
            );

            state.versionData = {
              ...state.versionData,
              calculated_version: {
                ...state.versionData.calculated_version,
                [systemIndex]: {
                  ...state.versionData.calculated_version[systemIndex],
                  value: {
                    ...state.versionData.calculated_version[systemIndex].value,
                    annual: newValues
                  }
                }
              }
            };
          }

          if (projectionConstant === "last-period") {
            const lastPeriodValue =
              currentRowAllValues.annual[numberOfHistoricalYears - 1];

            const newValues = getNewValues(
              value,
              lastPeriodValue,
              projectionValues,
              numberOfHistoricalYears,
              state.versionData.calculated_version[systemIndex].value.annual,
              currentFormat
            );

            state.versionData = {
              ...state.versionData,
              calculated_version: {
                ...state.versionData.calculated_version,
                [systemIndex]: {
                  ...state.versionData.calculated_version[systemIndex],
                  value: {
                    ...state.versionData.calculated_version[systemIndex].value,
                    annual: newValues
                  }
                }
              }
            };
          }
        }
        if (!isAnnualAnalysis) {
          const numberOfHistoricalQuarters =
            versionData.version_meta_data.historical_quarters;
          const historicalQuarterValues = currentRowAllValues.quarterly.slice(
            0,
            numberOfHistoricalQuarters
          );
          const projectionQuarterValues = currentRowAllValues.quarterly.slice(
            numberOfHistoricalQuarters,
            currentRowAllValues.quarterly.length
          );

          const growthValues =
            currentRowHistoricalType === "growth"
              ? historicalQuarterValues.slice(4, historicalQuarterValues.length)
              : historicalQuarterValues;

          if (projectionConstant === "average") {
            const averageValue =
              growthValues.reduce((a, b) => a + b, 0) / growthValues.length;

            const newValues = getNewValues(
              value,
              averageValue,
              projectionQuarterValues,
              numberOfHistoricalQuarters,
              state.versionData.calculated_version[systemIndex].value.quarterly,
              currentFormat
            );

            state.versionData = {
              ...state.versionData,
              calculated_version: {
                ...state.versionData.calculated_version,
                [systemIndex]: {
                  ...state.versionData.calculated_version[systemIndex],
                  value: {
                    ...state.versionData.calculated_version[systemIndex].value,
                    quarterly: newValues
                  }
                }
              }
            };
          }

          if (projectionConstant === "median") {
            const medianValue = getMedianValue(growthValues);

            const newValues = getNewValues(
              value,
              medianValue,
              projectionQuarterValues,
              numberOfHistoricalQuarters,
              state.versionData.calculated_version[systemIndex].value.quarterly,
              currentFormat
            );

            state.versionData = {
              ...state.versionData,
              calculated_version: {
                ...state.versionData.calculated_version,
                [systemIndex]: {
                  ...state.versionData.calculated_version[systemIndex],
                  value: {
                    ...state.versionData.calculated_version[systemIndex].value,
                    quarterly: newValues
                  }
                }
              }
            };
          }

          if (projectionConstant === "last-period") {
            const lastPeriodValue =
              historicalQuarterValues[historicalQuarterValues.length - 1];

            const newValues = getNewValues(
              value,
              lastPeriodValue,
              projectionQuarterValues,
              numberOfHistoricalQuarters,
              state.versionData.calculated_version[systemIndex].value.quarterly,
              currentFormat
            );

            state.versionData = {
              ...state.versionData,
              calculated_version: {
                ...state.versionData.calculated_version,
                [systemIndex]: {
                  ...state.versionData.calculated_version[systemIndex],
                  value: {
                    ...state.versionData.calculated_version[systemIndex].value,
                    quarterly: newValues
                  }
                }
              }
            };
          }
        }
      }
    },

    // dashboard
    setNewChartOrder: (state, action) => {
      state.dashboard = {
        ...state.dashboard,
        dashboard_charts: action.payload
      };
    }
  },
  extraReducers: {
    // analysis

    [getCustomerAnalysisData.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getCustomerAnalysisData.fulfilled.toString()]: (
      state,
      action: PayloadAction<{
        metadata: ICreateAnalysisResponse;
        versionData: IVersionDataJson;
        availableCharts: IAvailableChart[];
        dashboardData: IDashboard;
        report: IReportResponse;
        templateData: ITemplateResponse;
      }>
    ) => {
      state.loading = false;
      state.loadingReport = false;

      const fieldsFromTemplate =
        action.payload.templateData.subtemplates[0].fields;
      const defaultCharts = action.payload.templateData.charts.filter(
        (chart: IChartTemplate) => chart.is_default_for_analysis
      );

      const helperFieldsFromTemplate = fieldsFromTemplate
        .map((item) => item.subfields)
        .flat()
        .filter(
          (field) =>
            field.system_index.includes("helper") &&
            !field.system_index.includes("child")
        );

      let newMetadata = {} as any;

      Object.entries(action.payload.metadata.meta_data).forEach(
        ([key, value]: any) => {
          const result = fieldsFromTemplate.find(
            (item) => item.system_index === key
          );

          newMetadata = {
            ...newMetadata,

            [key]: {
              ...value,
              available_on_assumption_page: result
                ? result.available_on_assumption_page
                : true
            }
          };
        }
      );

      state.metadata = {
        ...action.payload.metadata,
        meta_data: newMetadata
      };

      let newFields = {} as IVersionDataField;

      Object.entries(
        action.payload.versionData.version_meta_data.fields
      ).forEach(([key, value]: any) => {
        if (key.includes("helper")) {
          const resultHelper = helperFieldsFromTemplate.find(
            (item) => item.system_index === key
          );
          newFields = {
            ...newFields,
            [key]: {
              ...value,
              display_instead_of_parent: resultHelper
                ? resultHelper.display_instead_of_parent
                : false,
              styles: [...value.styles, "indent"]
            }
          };
        } else {
          const result = fieldsFromTemplate.find(
            (item) => item.system_index === key
          );

          newFields = {
            ...newFields,

            [key]: {
              ...value,
              available_on_assumption_page: result
                ? result.available_on_assumption_page
                : true
            }
          };
        }
      });

      state.versionData = {
        ...action.payload.versionData,
        version_meta_data: {
          ...action.payload.versionData.version_meta_data,
          fields: newFields
        }
      };
      state.analysisWorksheets = [...action.payload.metadata.worksheets].sort(
        (ws1: IAnalysisWorksheet, ws2: IAnalysisWorksheet) =>
          ws1.order_number - ws2.order_number
      );
      state.availableCharts = action.payload.availableCharts;
      state.dashboard = action.payload.dashboardData;
      state.report = action.payload.report;
      state.defaultCharts = defaultCharts;

      let calculatedVersion: ICalculatedVersion = {};

      Object.entries(action.payload.versionData.calculated_version).forEach(
        ([key, values]: [string, any]) => {
          if (
            !key.includes("child") &&
            !key.includes("helper") &&
            !key.includes("plug")
          ) {
            const defaultGroup =
              action.payload.versionData.version_meta_data.fields[
                key
              ].available_logics_groups.find(
                (group) => group.name === values.logics_group
              );

            Object.assign(state.currentGroup, {
              [key]: {
                name: defaultGroup?.name || "",
                explanations: defaultGroup?.explanations || ""
              }
            });
          }

          calculatedVersion = {
            ...calculatedVersion,
            [key]: {
              ...action.payload.versionData.calculated_version[key],
              interval: action.payload.versionData.calculated_version[key]
                .interval
                ? action.payload.versionData.calculated_version[key].interval
                : "default"
            }
          };

          state.versionData.calculated_version = calculatedVersion;
        }
      );
    },
    [getCustomerAnalysisData.rejected.toString()]: (state) => {
      state.loading = false;
    },
    // version data
    [getVersionData.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getVersionData.fulfilled.toString()]: (
      state,
      action: PayloadAction<IVersionDataJson>
    ) => {
      state.loading = false;
      state.versionData = action.payload;
    },
    [getVersionData.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // move projection columns
    [moveProjection.pending.toString()]: (state) => {},
    [moveProjection.fulfilled.toString()]: (
      state,
      action: PayloadAction<{
        response: IMovePeriodResponse;
        positionIndex: string;
        dateTitle: string;
      }>
    ) => {
      const newHistoricalData = {
        ...action.payload.response.new_historical_data
      };
      const { positionIndex, dateTitle } = action.payload;

      if (isEmpty(newHistoricalData)) {
        state.isExistDataMovePeriod = false;
        return;
      }

      const isQuarter =
        state.versionData.version_meta_data.selected_periods.includes(
          "quarterly"
        );
      const currentComparableIndexes = state.versionData.version_meta_data.extra
        ?.comparable_indexes || {
        annual: [],
        quarterly: []
      };

      if (isQuarter) {
        state.versionData = {
          ...state.versionData,
          version_meta_data: {
            ...state.versionData.version_meta_data,
            historical_quarters: Number(
              state.versionData.version_meta_data.historical_quarters + 1
            ),
            projection_quarters: Number(
              state.versionData.version_meta_data.projection_quarters - 1
            ),
            historical_years: dateTitle.includes("Q4")
              ? Number(state.versionData.version_meta_data.historical_years + 1)
              : state.versionData.version_meta_data.historical_years,
            projection_years: dateTitle.includes("Q4")
              ? Number(state.versionData.version_meta_data.projection_years - 1)
              : state.versionData.version_meta_data.projection_years,
            extra: {
              comparable_indexes: {
                ...currentComparableIndexes,
                annual: !dateTitle.includes("Q")
                  ? [...currentComparableIndexes.annual, Number(positionIndex)]
                  : [...currentComparableIndexes.annual],
                quarterly: dateTitle.includes("Q")
                  ? [
                      ...currentComparableIndexes.quarterly,
                      Number(positionIndex)
                    ]
                  : [...currentComparableIndexes.quarterly]
              }
            }
          }
        };
      } else {
        state.versionData = {
          ...state.versionData,
          version_meta_data: {
            ...state.versionData.version_meta_data,
            historical_years: Number(
              state.versionData.version_meta_data.historical_years + 1
            ),
            projection_years: Number(
              state.versionData.version_meta_data.projection_years - 1
            ),
            extra: {
              comparable_indexes: {
                ...currentComparableIndexes,
                annual: [
                  ...currentComparableIndexes.annual,
                  Number(positionIndex)
                ],
                quarterly: []
              }
            }
          }
        };
      }

      Object.entries(newHistoricalData).forEach(([key, value]: any) => {
        const annualValue = cloneDeep(
          state.versionData.calculated_version[key].value.annual
        );
        const quarterlyValue = cloneDeep(
          state.versionData.calculated_version[key].value.quarterly
        );

        if (isQuarter) {
          const quarterIndex =
            state.versionData.version_meta_data.position_indexes.quarterly.indexOf(
              Number(positionIndex)
            );
          quarterlyValue[quarterIndex] = value;
        }

        annualValue[Number(positionIndex)] = value;

        if (isQuarter) {
          state.versionData.calculated_version = {
            ...state.versionData.calculated_version,
            [key]: {
              ...state.versionData.calculated_version[key],
              value: {
                ...state.versionData.calculated_version[key].value,
                quarterly: quarterlyValue,

                expectedQuarterly:
                  state.versionData.calculated_version[key].value.quarterly,
                annual: dateTitle.includes("Q4")
                  ? Array(
                      state.versionData.calculated_version[key].value.annual
                        .length
                    ).fill(0)
                  : state.versionData.calculated_version[key].value.annual,
                expectedAnnual: dateTitle.includes("Q4")
                  ? Array(
                      state.versionData.calculated_version[key].value.annual
                        .length
                    ).fill(0)
                  : state.versionData.calculated_version[key].value.annual
              }
            }
          };
        } else {
          state.versionData.calculated_version = {
            ...state.versionData.calculated_version,
            [key]: {
              ...state.versionData.calculated_version[key],
              value: {
                ...state.versionData.calculated_version[key].value,
                annual: annualValue,
                expectedAnnual:
                  state.versionData.calculated_version[key].value.annual
              }
            }
          };
        }
      });
    },
    [moveProjection.rejected.toString()]: (state) => {},

    // available charts
    [getAvailableCharts.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getAvailableCharts.fulfilled.toString()]: (
      state,
      action: PayloadAction<IAvailableChart[]>
    ) => {
      state.loading = false;
      state.availableCharts = action.payload;
    },
    [getAvailableCharts.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // dashboard
    [getCustomerDashboardData.pending.toString()]: (state) => {},
    [getCustomerDashboardData.fulfilled.toString()]: (
      state,
      action: PayloadAction<IDashboard>
    ) => {
      state.dashboard = action.payload;
    },
    [getCustomerDashboardData.rejected.toString()]: (state) => {},
    [createChart.pending.toString()]: (state) => {
      state.loading = true;
    },
    [createChart.fulfilled.toString()]: (
      state,
      action: PayloadAction<{
        chart: IChart;
        dates: IDates;
        report: IReportResponse | null;
      }>
    ) => {
      state.loading = false;
      state.dashboard.dashboard_charts = [
        ...state.dashboard.dashboard_charts,
        action.payload.chart
      ];
    },
    [createChart.rejected.toString()]: (state) => {
      state.loading = false;
    },
    [deleteChart.pending.toString()]: (state) => {
      state.loading = true;
    },
    [deleteChart.fulfilled.toString()]: (
      state,
      action: PayloadAction<number>
    ) => {
      state.loading = false;
      state.dashboard.dashboard_charts =
        state.dashboard.dashboard_charts.filter(
          (chart: any) => chart.id !== action.payload
        );
    },
    [deleteChart.rejected.toString()]: (
      state,
      action: PayloadAction<number>
    ) => {
      state.loading = false;
    },

    // report
    [getReport.pending.toString()]: (state) => {
      state.loadingReport = true;
    },
    [getReport.fulfilled.toString()]: (
      state,
      action: PayloadAction<IReportResponse>
    ) => {
      state.loadingReport = false;
      state.report = action.payload;
    },
    [getReport.rejected.toString()]: (state) => {
      state.loadingReport = false;
    },

    // stock symbol
    [getStockSymbols.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getStockSymbols.fulfilled.toString()]: (
      state,
      action: PayloadAction<IAutocompleteResponse[]>
    ) => {
      state.loading = false;
      state.stockSymbolList = action.payload;
    },
    [getStockSymbols.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // duplicate analysis
    [duplicateAnalysis.pending.toString()]: (state) => {
      state.loading = true;
    },
    [duplicateAnalysis.fulfilled.toString()]: (
      state,
      action: PayloadAction<IDuplicateAnalysisResponse>
    ) => {
      state.loading = false;
      state.analyses = [...state.analyses, action.payload];
    },
    [duplicateAnalysis.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // industry page
    [getIndustryCompanyAnalysis.pending.toString()]: (state) => {
      state.loadingIndustry = true;
    },
    [getIndustryCompanyAnalysis.fulfilled.toString()]: (
      state,
      action: PayloadAction<IIndustryAnalysis[]>
    ) => {
      state.loadingIndustry = false;
      state.industryAnalysis = action.payload;
    },
    [getIndustryCompanyAnalysis.rejected.toString()]: (state, action) => {
      state.loadingIndustry = false;
    },
    [getComparableData.pending.toString()]: (state) => {
      state.loadingGetComparableData = true;
    },
    [getComparableData.fulfilled.toString()]: (
      state,
      action: PayloadAction<IComparableCompanyData[]>
    ) => {
      state.loadingGetComparableData = false;
      state.comparableCompanyData = action.payload;
      state.currentStockSymbols = action.payload.map((item) => item.symbol);
      state.financialDataYear = action.payload
        .flatMap((item) =>
          Object.values(item.financial_data.yearly).map((year) => year.year)
        )
        .filter((item, index, self) => self.indexOf(item) === index)
        .sort((a, b) => a - b);
    },
    [getComparableData.rejected.toString()]: (state) => {
      state.loadingGetComparableData = false;
    },
    [getExistingComparableData.pending.toString()]: (state) => {
      state.loadingGetComparableData = true;
    },
    [getExistingComparableData.fulfilled.toString()]: (
      state,
      action: PayloadAction<IComparableCompanyData[]>
    ) => {
      state.loadingGetComparableData = false;
      state.comparableCompanyData = action.payload;
      state.currentStockSymbols = action.payload.map((item) => item.symbol);
      state.selectedCompanies = action.payload
        .map((item) => item.symbol)
        .filter((item, index, self) => self.indexOf(item) === index);
      state.financialDataYear = action.payload
        .flatMap((item) =>
          Object.values(item.financial_data.yearly).map((year) => year.year)
        )
        .filter((item, index, self) => self.indexOf(item) === index)
        .sort((a, b) => a - b);
    },
    [getExistingComparableData.rejected.toString()]: (state) => {
      state.loadingGetComparableData = false;
    },
    [getIndustryCountries.pending.toString()]: (state) => {
      state.loadingIndustryCountries = true;
    },
    [getIndustryCountries.fulfilled.toString()]: (
      state,
      action: PayloadAction<IIndustryCountry[]>
    ) => {
      state.loadingIndustryCountries = false;
      state.industryCountries = action.payload
        .map((item) => item.name.trim())
        .filter((item, index, self) => self.indexOf(item) === index);
    },
    [getIndustryCountries.rejected.toString()]: (state) => {
      state.loadingIndustryCountries = false;
    },
    [getSwot.pending.toString()]: (state) => {
      state.loadingSwot = true;
    },
    [getSwot.fulfilled.toString()]: (
      state,
      action: PayloadAction<IIndustryWithSwot>
    ) => {
      state.loadingSwot = false;
      state.industryWithSwot = action.payload;
    },
    [getSwot.rejected.toString()]: (state) => {
      state.loadingSwot = false;
    },
    [getSwotTranslated.pending.toString()]: (state) => {
      state.loadingSwot = true;
    },
    [getSwotTranslated.fulfilled.toString()]: (
      state,
      action: PayloadAction<IIndustryWithSwot>
    ) => {
      state.loadingSwot = false;
      state.industryWithSwot = action.payload;
    },
    [getSwotTranslated.rejected.toString()]: (state) => {
      state.loadingSwot = false;
    },
    [getDataSourceRatio.pending.toString()]: (state) => {},
    [getDataSourceRatio.fulfilled.toString()]: (
      state,
      action: PayloadAction<IDataSourceRatio[]>
    ) => {
      state.dataSourceRatios = action.payload;
    },
    [getDataSourceRatio.rejected.toString()]: (state) => {},
    [getPriceTarget.pending.toString()]: () => {},
    [getPriceTarget.fulfilled.toString()]: (
      state,
      action: PayloadAction<IPriceTarget[]>
    ) => {
      state.priceTarget = action.payload;
    },
    [getPriceTarget.rejected.toString()]: () => {},
    [getLiveStockPrice.pending.toString()]: () => {},
    [getLiveStockPrice.fulfilled.toString()]: (
      state,
      action: PayloadAction<ILiveStockPrice[]>
    ) => {
      state.stockPrice = action.payload;
    },
    [getLiveStockPrice.rejected.toString()]: () => {},
    [getKeyMetrics.pending.toString()]: (state) => {
      state.loadingKeyMetrics = true;
    },
    [getKeyMetrics.fulfilled.toString()]: (
      state,
      action: PayloadAction<IKeyMetric[]>
    ) => {
      state.loadingKeyMetrics = false;
      state.keyMetrics = action.payload;
    },
    [getKeyMetrics.rejected.toString()]: (state) => {
      state.loadingKeyMetrics = false;
    },
    [getPiotroskiScore.pending.toString()]: (state) => {
      state.loadingKeyMetrics = true;
    },
    [getPiotroskiScore.fulfilled.toString()]: (
      state,
      action: PayloadAction<IPiotroskiScore[]>
    ) => {
      state.loadingKeyMetrics = false;
      state.piotroskiScores = action.payload;
    },
    [getPiotroskiScore.rejected.toString()]: (state) => {
      state.loadingKeyMetrics = true;
    }
  }
});

export const {
  setMetadata,
  onClickCalculate,
  getAddChildResponse,
  onAddChildRow,
  onDeleteChildRow,
  onChangeLogicGroup,
  onFinishEditCell,
  onAddProjectionColumn,
  onAddRowStyling,
  onSelectVersion,
  onDeleteVersion,
  getAnalysisDataFirstStep,
  getAnalysisDataSecondStep,
  getAnalysisDataThirdStep,
  getCurrentGroup,
  onConfirmMoveProjectionColumn,
  onCancelMoveProjectionColumn,
  onSaveOpenedCompareColumn,
  selectInterval,
  selectCompany,
  updateVersionName,
  onFinishEditCellForEmptyHistorical,
  getCurrentColumnId,
  getCurrentDateTitle,
  setAiContentCompanyProfile,
  setAiContentCompanySwot,
  setAiContentSupplyChain,
  setAiContentWorksheetReport,
  setAiContentCharts,
  setIsAiGenerated,
  setIsGenerating,
  setCurrentReport,
  setIsLeavingReport,
  setSelectedVersion,
  setCalculatorTabIndex,
  setCurrentAnalysisId,
  setProjectionConstant,
  onChangeProjectConstantValue,
  onSubmitProjectionConstant,
  setNewChartOrder
} = analysesSlice.actions;

export default analysesSlice.reducer;
