import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

import { Modal, Button } from "components";
import popupBg from "assets/popup-bg.jpg";
import logo from "assets/logo.png";

interface UnassignedWarningProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function UnassignedWarningPopup({
  isOpen,
  onClose
}: UnassignedWarningProps) {
  const { t } = useTranslation();

  return (
    <Modal hideHeader open={isOpen} onClose={onClose} width={700}>
      <Box className="grid grid-cols-2" sx={{ minHeight: "350px" }}>
        <Box
          sx={{
            backgroundImage: `url(${popupBg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "100%",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px"
          }}
        />

        <div className="p-4 flex flex-col items-center justify-center gap-6">
          <img className="max-w-40" src={logo} alt="logo" />
          <p className="text-center text-lg font-semibold">
            {t("app:you-have-successfully-registered")}
          </p>
          <p className="text-center">{t("app:to-get-started")}</p>

          <Button className="w-32" variant="contained" onClick={onClose}>
            {t("common:button.close")}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
