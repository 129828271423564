import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";

import { Modal, Button } from "components";
import paymentFailedBg from "assets/payment-failed.jpg";
import logo from "assets/logo.png";

interface FailedByPaymentPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function FailedPaymentPopup({
  isOpen,
  onClose
}: FailedByPaymentPopupProps) {
  const { t } = useTranslation();

  return (
    <Modal hideHeader open={isOpen} width={700} onClose={onClose}>
      <Box className="grid grid-cols-2" sx={{ minHeight: "350px" }}>
        <Box
          sx={{
            backgroundImage: `url(${paymentFailedBg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "100%",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px"
          }}
        />

        <div className="p-4 flex flex-col items-center justify-center gap-6">
          <img className="max-w-40" src={logo} alt="logo" />
          <p className="text-center text-lg font-semibold">
            {t("app:subscription.failed-by-payment-title")}
          </p>
          <div>
            <p>{t("app:subscription.failed-by-payment-message")}:</p>
            <ol className="list-decimal list-inside">
              <li>{t("app:subscription.failed-by-payment-option-one")}</li>
              <li>{t("app:subscription.failed-by-payment-option-two")}</li>
            </ol>
          </div>

          <Button className="w-32" variant="contained" onClick={onClose}>
            {t("common:button.close")}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
