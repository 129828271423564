// APP
export const PATH_BASE = "/app";
export const PATH_USER = "/app/user";
export const PATH_USER_PROFILE = "/app/user/profile";
export const PATH_USER_SUBSCRIPTION = "/app/user/subscription";
export const PATH_CALCULATOR = "/app/calculator";
export const PATH_ANALYSIS = "/app/analysis";
export const PATH_TEMPLATES = "/app/templates";
export const PATH_MEMBERS = "/app/members";
export const PATH_HELP = "/app/help";
export const PATH_CONTENT_CONNECT = "/app/content-connect";

// SUPER ADMIN
export const PATH_ADMIN_BASE = "/admin";
export const PATH_ADMIN_INDUSTRIES = "/admin/industries";
export const PATH_ADMIN_DATA_API = "/admin/data-api";
export const PATH_ADMIN_ROW_LOGICS = "/admin/row-logics";
export const PATH_ADMIN_TEMPLATES = "/admin/templates";
export const PATH_ADMIN_DASHBOARD = "/admin/dashboard";
export const PATH_ADMIN_TEMPLATES_CREATE = "/admin/templates/create";
export const PATH_ADMIN_TEMPLATES_EDIT = "/admin/templates/edit/:id";
export const PATH_ADMIN_ANALYSIS = "/admin/analysis";
export const PATH_ADMIN_USERS = "/admin/users";
export const PATH_ADMIN_SETTINGS = "/admin/settings";
export const PATH_ADMIN_REFERRAL = "/admin/referral";

// AUTHENTICATION
export const PATH_AUTH_BASE = "/auth";
export const PATH_LOGIN = "/auth/login";
export const PATH_REGISTER = "/auth/register";
export const PATH_ACCEPT_INVITATION = "/auth/accept-invitation";
export const PATH_RESET_PASSWORD = "/auth/reset-password";
export const PATH_REQUEST_RESET_PASSWORD = "/auth/request-reset-password";
export const PATH_EMAIL_CONFIRMATION = "/auth/email-confirmation";

// NOT FOUND
export const PATH_NOT_FOUND = "/404";
