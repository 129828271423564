import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import {
  IRow,
  IBasicSettings,
  ITemplateResponse,
  IWorksheet,
  IWorksheetRequest,
  IConvertedWorksheetList,
  IRatioGroup,
  IRatio,
  IChart,
  IDataProvider,
  IChartRow,
  ILogic,
  IGroup,
  IHelperLogic,
  IReportTemplateChart,
  IReportTemplateAiContent,
  IReportPlaceholderRequest,
  IReportPlaceholder
} from "types/template.model";
import { authorizedRequest } from "services/request";
import {
  isEmpty,
  convertSubtemplatesToRows,
  setFirstLanguage,
  checkObjectHasEmptyValue,
  setEnglishAsDefaultLanguage
} from "helpers/lib";
import { generateSystemIndex } from "modules/admin/templates/templates-form/rows/rows.helpers";

interface InitialState {
  loading: boolean;
  templates: ITemplateResponse[];
  basicSettings: IBasicSettings;
  rows: IRow[];
  visualLayout: IConvertedWorksheetList;
  worksheets: IWorksheet[];
  ratioGroups: IRatioGroup[];
  charts: IChart[];
  chartRows: IChartRow[];
  errors: {
    row: string;
    group: string;
    logic: string;
    chart: string;
  };
  currentLanguage: string;
  reportTemplateCharts: IReportTemplateChart[];
  reportTemplateAiContents: IReportTemplateAiContent;
  reportTemplatePlaceholders: IReportPlaceholder[];
  reportTemplatePlaceholderDetail: IReportPlaceholder | null;
}

const initialState: InitialState = {
  loading: true,
  templates: [],
  basicSettings: {
    name: "",
    type: "",
    industries: [],
    published_from: "",
    published_through: "",
    customer_groups: []
  },
  rows: [],
  ratioGroups: [],
  visualLayout: {},
  charts: [],
  chartRows: [],
  worksheets: [],
  errors: {
    row: "",
    group: "",
    logic: "",
    chart: ""
  },
  currentLanguage: "en",
  reportTemplateCharts: [],
  reportTemplateAiContents: {
    profile_content: [],
    worksheets: []
  },
  reportTemplatePlaceholders: [],
  reportTemplatePlaceholderDetail: null
};

export const getTemplates = createAsyncThunk(
  "templates/getTemplates",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get("/api/analyzes/templates/", {
        params
      });
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getTemplateDetail = createAsyncThunk(
  "templates/getTemplateDetail",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get(
        `/api/analyzes/templates/${id}/`
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const createWorksheet = createAsyncThunk(
  "templates/createWorksheet",
  async (state: IWorksheetRequest, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.post(
        "/api/analyzes/worksheets/",
        { ...state }
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const editWorksheet = createAsyncThunk(
  "templates/editWorksheet",
  async (
    state: { id: number; body: IWorksheetRequest },
    { rejectWithValue }
  ) => {
    try {
      const response = await authorizedRequest.put(
        `/api/analyzes/worksheets/${state.id}/`,
        { ...state.body }
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const deleteWorksheet = createAsyncThunk(
  "templates/deleteWorksheet",
  async (id: number, { rejectWithValue }) => {
    try {
      await authorizedRequest.delete(`/api/analyzes/worksheets/${id}/`);
      return id;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const duplicateTemplate = createAsyncThunk(
  "analysis/duplicateTemplate",
  async (state: { id: number }, { rejectWithValue }) => {
    const { id } = state;
    try {
      const response = authorizedRequest.get(
        `/api/analyzes/templates/${id}/duplicate/`
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getReportTemplateCharts = createAsyncThunk(
  "templates/getReportTemplates",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get(
        `/api/dashboard/template-charts/?template=${id}`
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getReportTemplateAiContents = createAsyncThunk(
  "templates/getReportTemplateAiContents",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get(
        `/api/analyzes/templates/${id}/ai-contents/`
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getReportTemplatePlaceholders = createAsyncThunk(
  "templates/getReportTemplatePlaceholders",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get(
        `/api/analyzes/report-placeholders/?template=${id}`
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const createReportPlaceholder = createAsyncThunk(
  "templates/createReportPlaceholder",
  async (state: IReportPlaceholderRequest, { rejectWithValue }) => {
    try {
      const response = (await authorizedRequest.post(
        `/api/analyzes/report-placeholders/`,
        { ...state }
      )) as IReportPlaceholder;
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const editReportPlaceholder = createAsyncThunk(
  "templates/editReportPlaceholder",
  async (
    state: {
      id: number;
      body: IReportPlaceholderRequest;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = (await authorizedRequest.put(
        `/api/analyzes/report-placeholders/${state.id}/`,
        { ...state.body }
      )) as IReportPlaceholder;
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const deleteReportPlaceholder = createAsyncThunk(
  "templates/deleteReportPlaceholder",
  async (
    state: {
      id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await authorizedRequest.delete(
        `/api/analyzes/report-placeholders/${state.id}/`
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const templateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    // basic settings
    onChangeName: (state, action) => {
      state.basicSettings.name = action.payload;
    },
    onChangeType: (state, action) => {
      state.basicSettings.type = action.payload;
    },
    onChangeIndustry: (state, action) => {
      state.basicSettings.industries = action.payload;
    },
    onChangeUserGroups: (state, action) => {
      state.basicSettings.customer_groups = action.payload;
    },
    onChangePublishedDate: (state, action) => {
      state.basicSettings.published_from = action.payload;
    },
    onChangeExpiredDate: (state, action) => {
      state.basicSettings.published_through = action.payload;
    },

    // rows
    addNewRow: (state, action) => {
      state.rows = [
        ...state.rows,
        {
          ...action.payload,
          data_source: "none",
          groups: action.payload.groups.map((group: any) => ({
            ...group,
            logics: group.logics.map((logic: any) => ({
              ...logic,
              id: uuidv4(),
              data_source: "none"
            }))
          }))
        },
        {
          ...action.payload,
          data_source: "api",
          groups: action.payload.groups.map((group: any) => ({
            ...group,
            logics: group.logics.map((logic: any) => ({
              ...logic,
              id: uuidv4(),
              data_source: "api"
            }))
          }))
        }
      ];
    },
    deleteRow: (state, action) => {
      state.rows = state.rows.filter(
        (row) => row.system_index !== action.payload
      );
    },
    duplicateRow: (state, action) => {
      const currentRows = state.rows.filter(
        (row) => row.system_index === action.payload
      );

      if (currentRows.length > 0) {
        const newRows = currentRows.map((currentRow) => ({
          ...currentRow,
          system_index: generateSystemIndex(state.rows),
          id: uuidv4(),
          groups: currentRow.groups.map((group, index) => ({
            ...group,
            id: `group_${index + 1}`,
            logics: group.logics.map((logic: any) => ({
              ...logic,
              id: uuidv4(),
              child_field:
                !isEmpty(logic.child_field) &&
                !isEmpty(logic.child_field.system_index)
                  ? {
                      ...logic.child_field,
                      system_index: logic.child_field.system_index.replace(
                        currentRow.system_index,
                        generateSystemIndex(state.rows)
                      ),
                      helper_fields: logic.child_field.helper_fields.map(
                        (helper: any) => ({
                          ...helper,
                          system_index: helper.system_index.replace(
                            currentRow.system_index,
                            generateSystemIndex(state.rows)
                          )
                        })
                      )
                    }
                  : {
                      helper_fields: []
                    },
              plug_row:
                !isEmpty(logic.plug_row) &&
                !isEmpty(logic.plug_row.system_index)
                  ? {
                      ...logic.plug_row,
                      system_index: logic.plug_row.system_index.replace(
                        currentRow.system_index,
                        generateSystemIndex(state.rows)
                      )
                    }
                  : {},
              helper_fields: !isEmpty(logic.helper_fields)
                ? logic.helper_fields.map((helper: any) => ({
                    ...helper,
                    system_index: helper.system_index.replace(
                      currentRow.system_index,
                      generateSystemIndex(state.rows)
                    )
                  }))
                : []
            }))
          }))
        }));

        state.rows = [...state.rows, ...newRows];
      }
    },
    toggleCollapseRow: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.id === action.payload) {
          return {
            ...row,
            open: !row.open
          };
        }
        return row;
      });
    },
    collapseAllRows: (state) => {
      state.rows = state.rows.map((row) => ({ ...row, open: false }));
    },
    expandAllRows: (state) => {
      state.rows = state.rows.map((row) => ({ ...row, open: true }));
    },
    onChangeRowName: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.systemIndex) {
          const rowTitle = { ...row.title };
          if (checkObjectHasEmptyValue(rowTitle)) {
            return {
              ...row,
              title: setFirstLanguage(rowTitle, action.payload.value)
            };
          }
          return {
            ...row,
            title: {
              ...row.title,
              [state.currentLanguage]: action.payload.value
            }
          };
        }
        return row;
      });
    },
    onChangeRowSystemIndex: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.id === action.payload.rowId) {
          return {
            ...row,
            system_index: action.payload.systemIndex,
            groups: row.groups.map((group) => ({
              ...group,
              logics: group.logics.map((logic: any) => ({
                ...logic,
                child_field:
                  logic.child_status === "with_child"
                    ? {
                        ...logic.child_field,
                        system_index: logic.child_field.system_index.replace(
                          row.system_index,
                          action.payload.systemIndex
                        ),
                        helper_fields:
                          logic.child_field.helper_fields.length > 0
                            ? logic.child_field.helper_fields.map(
                                (helper: any) => ({
                                  ...helper,
                                  system_index: helper.system_index.replace(
                                    row.system_index,
                                    action.payload.systemIndex
                                  )
                                })
                              )
                            : []
                      }
                    : {},
                plug_row: !isEmpty(logic.plug_row)
                  ? {
                      ...logic.plug_row,
                      system_index: logic.plug_row.system_index.replace(
                        row.system_index,
                        action.payload.systemIndex
                      )
                    }
                  : {},
                helper_fields:
                  logic.helper_fields.length > 0
                    ? logic.helper_fields.map((helper: any) => ({
                        ...helper,
                        system_index: helper.system_index.replace(
                          row.system_index,
                          action.payload.systemIndex
                        )
                      }))
                    : []
              }))
            }))
          };
        }
        return row;
      });
    },
    onChangeRowPeriod: (state, action) => {
      state.rows = [...state.rows].map((row) => {
        if (row.system_index === action.payload.systemIndex) {
          return {
            ...row,
            main_periods_logic: action.payload.value
          };
        }
        return row;
      });
    },
    onChangeRowInterval: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.systemIndex) {
          return {
            ...row,
            interval: action.payload.value
          };
        }
        return row;
      });
    },
    onAddRowFormat: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.systemIndex) {
          return {
            ...row,
            default_format: action.payload.value
          };
        }
        return row;
      });
    },
    onToggleShowAssumption: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.systemIndex) {
          return {
            ...row,
            available_on_assumption_page: action.payload.value
          };
        }
        return row;
      });
    },

    // group logic
    addNewGroup: (state, action) => {
      state.rows = state.rows.map((row: any) => {
        if (
          row.system_index === action.payload.rowSystemIndex &&
          row.data_source === "api"
        ) {
          return {
            ...row,
            groups: [
              ...row.groups,
              {
                ...action.payload.group,
                logics: action.payload.group.logics.map((logic: any) => ({
                  ...logic,
                  id: uuidv4(),
                  data_source: "api"
                }))
              }
            ]
          };
        }
        if (
          row.system_index === action.payload.rowSystemIndex &&
          row.data_source === "none"
        ) {
          return {
            ...row,
            groups: [
              ...row.groups,
              {
                ...action.payload.group,
                logics: action.payload.group.logics.map((logic: any) => ({
                  ...logic,
                  id: uuidv4(),
                  data_source: "none"
                }))
              }
            ]
          };
        }
        return row;
      });
    },
    deleteGroup: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.filter(
              (group) => group.id !== action.payload.groupId
            )
          };
        }
        return row;
      });
    },
    onChangeDefaultGroup: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  isDefault: action.payload.isDefault
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onChangeGroupName: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  name: action.payload.name,
                  logics: group.logics.map((logic) => ({
                    ...logic,
                    group: action.payload.name,
                    child_field: {
                      ...logic.child_field,
                      helper_fields:
                        logic.child_field.helper_fields.length > 0
                          ? logic.child_field.helper_fields.map((helper) => ({
                              ...helper,
                              group: action.payload.name
                            }))
                          : []
                    }
                  }))
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onChangeGroupExplanation: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  explanations: {
                    ...group.explanations,
                    [state.currentLanguage]: action.payload.explanations
                  }
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },

    // row logic
    addLogicSet: (state, action) => {
      const noneDataSourceLogicSetId = `${uuidv4()}_none`;
      const apiDataSourceLogicSetId = `${uuidv4()}_api`;

      state.rows = state.rows.map((row) => {
        if (
          row.system_index === action.payload.rowSystemIndex &&
          row.data_source === "api"
        ) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: [
                    ...group.logics,
                    {
                      ...action.payload.logic,
                      id: uuidv4(),
                      group: group.name,
                      logicSetId: apiDataSourceLogicSetId,
                      period: "historical",
                      data_source: "api"
                    },
                    {
                      ...action.payload.logic,
                      id: uuidv4(),
                      group: group.name,
                      logicSetId: apiDataSourceLogicSetId,
                      period: "projection",
                      data_source: "api"
                    }
                  ]
                };
              }
              return group;
            })
          };
        }
        if (
          row.system_index === action.payload.rowSystemIndex &&
          row.data_source === "none"
        ) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: [
                    ...group.logics,
                    {
                      ...action.payload.logic,
                      id: uuidv4(),
                      group: group.name,
                      logicSetId: noneDataSourceLogicSetId,
                      period: "historical",
                      data_source: "none"
                    },
                    {
                      ...action.payload.logic,
                      id: uuidv4(),
                      group: group.name,
                      logicSetId: noneDataSourceLogicSetId,
                      period: "projection",
                      data_source: "none"
                    }
                  ]
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    deleteLogicSet: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.filter(
                    (logic: any) =>
                      logic.logicSetId !== action.payload.logicSetId
                  )
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onchangeRowLogicPeriod: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logicId) {
                      return {
                        ...logic,
                        period: action.payload.period
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onchangeRowLogicCalculationType: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logicId) {
                      return {
                        ...logic,
                        calc_type: action.payload.calculationType
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onchangeRowLogicChildStatus: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              const currentChildWithTitle = group.logics.find(
                (currentLogic: ILogic) =>
                  !isEmpty(currentLogic.child_field.title)
              );
              let childTitle: { [key: string]: string } = {
                en: "",
                zh: ""
              };
              if (currentChildWithTitle)
                childTitle = {
                  ...currentChildWithTitle.child_field.title,
                  ...childTitle
                };
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logicId) {
                      return {
                        ...logic,
                        child_status: action.payload.hasChild
                          ? "with_child"
                          : "no_child",
                        child_field: {
                          system_index: `${row.system_index}_child`,
                          period: logic.period,
                          parent_logic: logic.calc_type,
                          type: "child",
                          title: childTitle,
                          logic: "",
                          formula: "",
                          helper_fields: []
                        }
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onChangeRowLogicFormula: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logicId) {
                      return {
                        ...logic,
                        formula: action.payload.formula
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    insertRowToFormula: (state, action) => {
      const { selectedRowSystemIndex, rowId, groupId, logicId } =
        action.payload;

      state.rows = state.rows.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === logicId) {
                      return {
                        ...logic,
                        formula: `${logic.formula}${selectedRowSystemIndex}`
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },

    // helper logic
    addRowLogicHelper: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logicId) {
                      return {
                        ...logic,
                        helper_fields: [
                          ...logic.helper_fields,
                          action.payload.helper
                        ]
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    addRowLogicExistingHelper: (state, action) => {
      const helpers = state.rows
        .map((row) =>
          row.groups
            .map((group) =>
              group.logics.map((logic: any) => logic.helper_fields).flat()
            )
            .flat()
        )
        .flat();

      const currentHelper = helpers.find(
        (helper) => helper.id === action.payload.helperId
      );
      let newHelper: typeof currentHelper = {};
      if (
        currentHelper.period === action.payload.logic.period &&
        currentHelper.child_status === action.payload.logic.child_status
      ) {
        newHelper = { ...currentHelper };
      } else {
        newHelper = {
          ...currentHelper,
          id: uuidv4(),
          calc_type: "",
          formula: "",
          title: {
            en: "",
            zh: ""
          },
          child_status: action.payload.logic.child_status,
          period: action.payload.logic.period
        };
      }
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logic.id) {
                      return {
                        ...logic,
                        helper_fields: [...logic.helper_fields, newHelper]
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    deleteRowLogicHelper: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logicId) {
                      return {
                        ...logic,
                        helper_fields: logic.helper_fields.filter(
                          (helper: any) => helper.id !== action.payload.helperId
                        )
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onChangeRowLogicHelperTitle: (state, action) => {
      state.rows = state.rows.map((row: any) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group: any) => ({
              ...group,
              logics: group.logics.map((logic: any) => ({
                ...logic,
                helper_fields: logic.helper_fields.map((helper: any) => {
                  if (
                    helper.system_index === action.payload.helperSystemIndex
                  ) {
                    const helperTitle = { ...helper.title };
                    if (checkObjectHasEmptyValue(helperTitle)) {
                      return {
                        ...helper,
                        title: setFirstLanguage(
                          helperTitle,
                          action.payload.title
                        )
                      };
                    }
                    return {
                      ...helper,
                      title: {
                        ...helper.title,
                        [state.currentLanguage]: action.payload.title
                      }
                    };
                  }
                  return helper;
                })
              }))
            }))
          };
        }
        return row;
      });
    },
    onChangeRowLogicHelperCalculationType: (state, action) => {
      state.rows = state.rows.map((row: any) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group: any) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (
                      (logic.period === action.payload.logic.period &&
                        logic.child_status ===
                          action.payload.logic.child_status) ||
                      logic.id === action.payload.logic.id
                    ) {
                      return {
                        ...logic,
                        helper_fields: logic.helper_fields.map(
                          (helper: any) => {
                            if (helper.id === action.payload.helperId) {
                              return {
                                ...helper,
                                calc_type: action.payload.calculationType
                              };
                            }
                            return helper;
                          }
                        )
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onChangeRowLogicHelperFormula: (state, action) => {
      state.rows = state.rows.map((row: any) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group: any) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (
                      (logic.period === action.payload.logic.period &&
                        logic.child_status ===
                          action.payload.logic.child_status) ||
                      logic.id === action.payload.logic.id
                    ) {
                      return {
                        ...logic,
                        helper_fields: logic.helper_fields.map(
                          (helper: any) => {
                            if (helper.id === action.payload.helperId) {
                              return {
                                ...helper,
                                formula: action.payload.formula
                              };
                            }
                            return helper;
                          }
                        )
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onChangeHideDisplayParentField: (state, action) => {
      state.rows = state.rows.map((row: IRow) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group: IGroup) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: ILogic) => ({
                    ...logic,
                    helper_fields: logic.helper_fields.map(
                      (helper: IHelperLogic) => {
                        if (
                          helper.system_index ===
                          action.payload.helperSystemIndex
                        ) {
                          return {
                            ...helper,
                            display_instead_of_parent:
                              !helper.display_instead_of_parent
                          };
                        }
                        return helper;
                      }
                    )
                  }))
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onAddHelperFormat: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic) => ({
                    ...logic,
                    helper_fields: logic.helper_fields.map((helper) => {
                      if (
                        helper.system_index === action.payload.helperSystemIndex
                      ) {
                        return {
                          ...helper,
                          default_format: action.payload.defaultFormat
                        };
                      }
                      return helper;
                    })
                  }))
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },

    // child logic
    onChangeRowLogicChildTitle: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => ({
              ...group,
              logics: group.logics.map((logic: ILogic) => {
                const childTitle = { ...logic.child_field.title };
                if (Object.values(childTitle).every((item) => item === "")) {
                  return {
                    ...logic,
                    child_field: {
                      ...logic.child_field,
                      title: setFirstLanguage(childTitle, action.payload.title)
                    }
                  };
                }
                return {
                  ...logic,
                  child_field: {
                    ...logic.child_field,
                    title: {
                      ...logic.child_field.title,
                      [state.currentLanguage]: action.payload.title
                    }
                  }
                };
              })
            }))
          };
        }
        return row;
      });
    },
    onChangeRowLogicChildCalculationType: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logicId) {
                      return {
                        ...logic,
                        child_field: {
                          ...logic.child_field,
                          calc_type: action.payload.calculationType
                        }
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onChangeRowLogicChildFormula: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logicId) {
                      return {
                        ...logic,
                        child_field: {
                          ...logic.child_field,
                          formula: action.payload.formula
                        }
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onAddChildRowFormat: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic) => ({
                    ...logic,
                    child_field: {
                      ...logic.child_field,
                      default_format: action.payload.defaultFormat
                    }
                  }))
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },

    // child helper logic
    addChildHelper: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logicId) {
                      return {
                        ...logic,
                        child_field: {
                          ...logic.child_field,
                          helper_fields: [
                            ...logic.child_field.helper_fields,
                            action.payload.childHelper
                          ]
                        }
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    addExistingChildHelper: (state, action) => {
      const helpers = state.rows
        .map((row) =>
          row.groups
            .map((group) =>
              group.logics
                .map((logic: any) => logic.child_field.helper_fields)
                .flat()
            )
            .flat()
        )
        .flat()
        .filter((helper: any) => helper);
      const currentHelper = helpers.find(
        (helper) => helper.id === action.payload.helperId
      );
      let newHelper: typeof currentHelper = {};
      if (currentHelper.period === action.payload.logic.period) {
        newHelper = { ...currentHelper };
      } else {
        newHelper = {
          ...currentHelper,
          id: uuidv4(),
          calc_type: "",
          formula: "",
          title: "",
          child_status: "child",
          period: action.payload.logic.period
        };
      }
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logic.id) {
                      return {
                        ...logic,
                        child_field: {
                          ...logic.child_field,
                          helper_fields: [
                            ...logic.child_field.helper_fields,
                            newHelper
                          ]
                        }
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    deleteChildHelper: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logicId) {
                      return {
                        ...logic,
                        child_field: {
                          ...logic.child_field,
                          helper_fields: logic.child_field.helper_fields.filter(
                            (helper: any) =>
                              helper.id !== action.payload.helperId
                          )
                        }
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onChangeChildHelperTitle: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => ({
              ...group,
              logics: group.logics.map((logic: any) => ({
                ...logic,
                child_field: {
                  ...logic.child_field,
                  helper_fields: logic.child_field.helper_fields.map(
                    (helper: any) => {
                      if (
                        helper.system_index === action.payload.helperSystemIndex
                      ) {
                        const helperTitle = { ...helper.title };
                        if (checkObjectHasEmptyValue(helperTitle)) {
                          return {
                            ...helper,
                            title: setFirstLanguage(
                              helperTitle,
                              action.payload.title
                            )
                          };
                        }
                        return {
                          ...helper,
                          title: {
                            ...helper.title,
                            [state.currentLanguage]: action.payload.title
                          }
                        };
                      }
                      return helper;
                    }
                  )
                }
              }))
            }))
          };
        }
        return row;
      });
    },
    onChangeChildHelperCalculationType: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logicId) {
                      return {
                        ...logic,
                        child_field: {
                          ...logic.child_field,
                          helper_fields: logic.child_field.helper_fields.map(
                            (helper: any) => {
                              if (helper.id === action.payload.helperId) {
                                return {
                                  ...helper,
                                  calc_type: action.payload.calculationType
                                };
                              }
                              return helper;
                            }
                          )
                        }
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onChangeChildHelperFormula: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => {
                    if (logic.id === action.payload.logicId) {
                      return {
                        ...logic,
                        child_field: {
                          ...logic.child_field,
                          helper_fields: logic.child_field.helper_fields.map(
                            (helper: any) => {
                              if (helper.id === action.payload.helperId) {
                                return {
                                  ...helper,
                                  formula: action.payload.formula
                                };
                              }
                              return helper;
                            }
                          )
                        }
                      };
                    }
                    return logic;
                  })
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },
    onAddChildHelperFormat: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => {
              if (group.id === action.payload.groupId) {
                return {
                  ...group,
                  logics: group.logics.map((logic: any) => ({
                    ...logic,
                    child_field: {
                      ...logic.child_field,
                      helper_fields: logic.child_field.helper_fields.map(
                        (helper: any) => {
                          if (
                            helper.system_index ===
                            action.payload.helperSystemIndex
                          ) {
                            return {
                              ...helper,
                              default_format: action.payload.defaultFormat
                            };
                          }
                          return helper;
                        }
                      )
                    }
                  }))
                };
              }
              return group;
            })
          };
        }
        return row;
      });
    },

    // plug row
    onChangeRowPlugRow: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => ({
              ...group,
              logics: group.logics.map((logic: any) => ({
                ...logic,
                plug_row: action.payload.status ? action.payload.plugRow : {}
              }))
            })),
            plug_row_status: action.payload.status
          };
        }
        return row;
      });
    },
    onChangePlugRowHistoricalLogic: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => ({
              ...group,
              logics: group.logics.map((logic: any) => ({
                ...logic,
                plug_row: {
                  ...logic.plug_row,
                  historical: {
                    calc_type: "plug",
                    logic: action.payload.logic
                  }
                }
              }))
            }))
          };
        }
        return row;
      });
    },
    onChangePlugRowProjectionLogic: (state, action) => {
      state.rows = state.rows.map((row) => {
        if (row.system_index === action.payload.rowSystemIndex) {
          return {
            ...row,
            groups: row.groups.map((group) => ({
              ...group,
              logics: group.logics.map((logic: any) => ({
                ...logic,
                plug_row: {
                  ...logic.plug_row,
                  projection: {
                    calc_type: "plug",
                    logic: action.payload.logic
                  }
                }
              }))
            }))
          };
        }
        return row;
      });
    },
    // errors
    getRowError: (state, action) => {
      state.errors = {
        ...state.errors,
        row: action.payload.value
      };
    },
    getGroupError: (state, action) => {
      state.errors = {
        ...state.errors,
        group: action.payload.value
      };
    },
    getLogicError: (state, action) => {
      state.errors = {
        ...state.errors,
        logic: action.payload.value
      };
    },
    getChartError: (state, action) => {
      state.errors = {
        ...state.errors,
        chart: action.payload.value
      };
    },

    // clear template detail when creating template
    clearTemplateDetail: (state) => {
      state.basicSettings = {
        name: "",
        type: "",
        industries: [],
        published_from: "",
        published_through: "",
        customer_groups: []
      };
      state.rows = [];
      state.ratioGroups = [];
      state.worksheets = [];
      state.charts = [];
    },

    // set new rows order
    setNewRowOrder: (state, action) => {
      const newRowOrderNoneDataSource = Object.values(action.payload)
        .map((item: any) => item.items)
        .flat();

      const newRowOrderApiDataSource = state.rows
        .filter((row) => row.data_source === "api")
        .map((rowItem) => {
          const currentRowItem: IRow = newRowOrderNoneDataSource.find(
            (currRow) => currRow.system_index === rowItem.system_index
          );

          if (currentRowItem) {
            return {
              ...rowItem,
              order_number: currentRowItem.order_number,
              worksheet: currentRowItem.worksheet
            };
          }

          return {
            ...rowItem
          };
        });

      state.rows = [...newRowOrderNoneDataSource, ...newRowOrderApiDataSource];
    },

    // ratio group
    addNewRatioGroup: (state, action) => {
      state.ratioGroups = [...state.ratioGroups, action.payload];
    },
    deleteRatioGroup: (state, action) => {
      state.ratioGroups = state.ratioGroups.filter(
        (group: IRatioGroup) => group.id !== action.payload
      );
    },
    toggleCollapseRatioGroup: (state, action) => {
      state.ratioGroups = state.ratioGroups.map((group: IRatioGroup) => {
        if (group.id === action.payload) {
          return {
            ...group,
            open: !group.open
          };
        }
        return group;
      });
    },
    collapseAllRatioGroups: (state) => {
      state.ratioGroups = state.ratioGroups.map((group: IRatioGroup) => ({
        ...group,
        open: false
      }));
    },
    expandAllRatioGroups: (state) => {
      state.ratioGroups = state.ratioGroups.map((group: IRatioGroup) => ({
        ...group,
        open: true
      }));
    },
    onChangeRatioGroupName: (state, action) => {
      state.ratioGroups = state.ratioGroups.map((group: IRatioGroup) => {
        if (group.id === action.payload.id) {
          const groupName = { ...group.name };
          if (checkObjectHasEmptyValue(groupName)) {
            return {
              ...group,
              name: setFirstLanguage(groupName, action.payload.value)
            };
          }
          return {
            ...group,
            name: {
              ...group.name,
              [state.currentLanguage]: action.payload.value
            },
            ratios: !isEmpty(group.ratios)
              ? group.ratios.map((ratio: IRatio, index: number) => {
                  if (
                    !isEmpty(action.payload.value) &&
                    state.currentLanguage === "en"
                  ) {
                    const baseSystemIndex = ratio.system_index
                      .substring(0, ratio.system_index.indexOf("_"))
                      .toUpperCase();

                    const firstFourChars =
                      action.payload.value.length > 0
                        ? action.payload.value
                            .replace(/\s/g, "")
                            .substring(0, 4)
                            .toUpperCase()
                        : action.payload.value.toUppercase();

                    return {
                      ...ratio,
                      system_index: !isEmpty(baseSystemIndex)
                        ? ratio.system_index.replace(
                            baseSystemIndex,
                            firstFourChars
                          )
                        : `${firstFourChars}_${10 * (index + 1)}`
                    };
                  }
                  return ratio;
                })
              : group.ratios
          };
        }
        return group;
      });
    },

    // ratio
    addNewRatio: (state, action) => {
      state.ratioGroups = state.ratioGroups.map((group: IRatioGroup) => {
        if (group.id === action.payload.groupId) {
          return {
            ...group,
            ratios: [...group.ratios, action.payload.ratio]
          };
        }
        return group;
      });
    },
    deleteRatio: (state, action) => {
      state.ratioGroups = state.ratioGroups.map((group: IRatioGroup) => {
        if (group.id === action.payload.groupId) {
          return {
            ...group,
            ratios: group.ratios.filter(
              (ratio: IRatio) => ratio.id !== action.payload.ratioId
            )
          };
        }
        return group;
      });
    },
    onChangeRatioSystemIndex: (state, action) => {
      state.ratioGroups = state.ratioGroups.map((group: IRatioGroup) => {
        if (group.id === action.payload.groupId) {
          return {
            ...group,
            ratios: group.ratios.map((ratio: IRatio) => {
              if (ratio.id === action.payload.ratioId) {
                return {
                  ...ratio,
                  system_index: action.payload.value
                };
              }
              return ratio;
            })
          };
        }
        return group;
      });
    },
    onChangeRatioTitle: (state, action) => {
      state.ratioGroups = state.ratioGroups.map((group: IRatioGroup) => {
        if (group.id === action.payload.groupId) {
          return {
            ...group,
            ratios: group.ratios.map((ratio: IRatio) => {
              if (ratio.id === action.payload.ratioId) {
                const ratioName = { ...ratio.title };
                if (checkObjectHasEmptyValue(ratioName)) {
                  return {
                    ...ratio,
                    title: setFirstLanguage(ratioName, action.payload.value)
                  };
                }
                return {
                  ...ratio,
                  title: {
                    ...ratio.title,
                    [state.currentLanguage]: action.payload.value
                  }
                };
              }
              return ratio;
            })
          };
        }
        return group;
      });
    },
    onChangeRatioFormula: (state, action) => {
      state.ratioGroups = state.ratioGroups.map((group: IRatioGroup) => {
        if (group.id === action.payload.groupId) {
          return {
            ...group,
            ratios: group.ratios.map((ratio: IRatio) => {
              if (ratio.id === action.payload.ratioId) {
                return {
                  ...ratio,
                  formula: action.payload.value
                };
              }
              return ratio;
            })
          };
        }
        return group;
      });
    },

    // charts
    getChartRows: (state) => {
      const rowList = state.rows
        .filter((row) => row.data_source === "api")
        .map((row) => ({
          system_index: row.system_index,
          title: row.title,
          type: "row"
        }));

      const ratioList = state.ratioGroups
        .map((item: IRatioGroup) => item.ratios)
        .flat()
        .map((ratio) => ({
          system_index: ratio.system_index,
          title: ratio.title,
          type: "ratio"
        }));

      state.chartRows = [...rowList, ...ratioList];
    },
    addNewChart: (state, action) => {
      state.charts = [...state.charts, action.payload];
    },
    toggleCollapseChart: (state, action) => {
      state.charts = state.charts.map((chart) => {
        if (chart.id === action.payload) {
          return {
            ...chart,
            open: !chart.open
          };
        }
        return chart;
      });
    },
    collapseAllCharts: (state) => {
      state.charts = state.charts.map((chart) => ({ ...chart, open: false }));
    },
    expandAllCharts: (state) => {
      state.charts = state.charts.map((chart) => ({ ...chart, open: true }));
    },
    deleteChart: (state, action) => {
      state.charts = state.charts.filter(
        (chart) => chart.id !== action.payload
      );
    },
    onChangeChartTitle: (state, action) => {
      state.charts = state.charts.map((chart: IChart) => {
        if (chart.id === action.payload.id) {
          const chartTitle = { ...chart.title };
          if (checkObjectHasEmptyValue(chartTitle)) {
            return {
              ...chart,
              title: setFirstLanguage(chartTitle, action.payload.value)
            };
          }
          return {
            ...chart,
            title: {
              ...chart.title,
              [state.currentLanguage]: action.payload.value
            }
          };
        }
        return chart;
      });
    },
    onChangeChartPrimaryType: (state, action) => {
      state.charts = state.charts.map((chart: IChart) => {
        if (chart.id === action.payload.id) {
          return {
            ...chart,
            primary_type: action.payload.value
          };
        }
        return chart;
      });
    },
    onChangeChartSecondaryType: (state, action) => {
      state.charts = state.charts.map((chart: IChart) => {
        if (chart.id === action.payload.id) {
          return {
            ...chart,
            secondary_type: action.payload.value
          };
        }
        return chart;
      });
    },
    onCheckChartDefault: (state, action) => {
      state.charts = state.charts.map((chart: IChart) => {
        if (chart.id === action.payload.id) {
          return {
            ...chart,
            is_default_for_analysis: action.payload.value
          };
        }
        return chart;
      });
    },
    setChartOrder: (state, action) => {
      state.charts = action.payload;
    },

    // chart row
    addNewChartRow: (state, action) => {
      state.charts = state.charts.map((chart: IChart) => {
        if (chart.id === action.payload.chartId) {
          return {
            ...chart,
            data_providers: [...chart.data_providers, action.payload.chartRow]
          };
        }
        return chart;
      });
    },
    deleteChartRow: (state, action) => {
      state.charts = state.charts.map((chart: IChart) => {
        if (chart.id === action.payload.chartId) {
          return {
            ...chart,
            data_providers: chart.data_providers.filter(
              (data) => data.id !== action.payload.dataId
            )
          };
        }
        return chart;
      });
    },
    onChangeChartRowData: (state, action) => {
      state.charts = state.charts.map((chart) => {
        if (chart.id === action.payload.chartId) {
          return {
            ...chart,
            data_providers: chart.data_providers.map((data: IDataProvider) => {
              if (data.id === action.payload.dataId) {
                return {
                  ...data,
                  system_index: action.payload.system_index,
                  is_ratio: action.payload.type === "ratio"
                };
              }
              return data;
            })
          };
        }
        return chart;
      });
    },
    onChangeSecondaryAxis: (state, action) => {
      state.charts = state.charts.map((chart) => {
        if (chart.id === action.payload.chartId) {
          return {
            ...chart,
            data_providers: chart.data_providers.map((data: IDataProvider) => {
              if (data.id === action.payload.dataId) {
                return {
                  ...data,
                  yaxis: action.payload.yaxis
                };
              }
              return data;
            })
          };
        }
        return chart;
      });
    },
    onChangeChartRowColor: (state, action) => {
      state.charts = state.charts.map((chart) => {
        if (chart.id === action.payload.chartId) {
          return {
            ...chart,
            data_providers: chart.data_providers.map((data: IDataProvider) => {
              if (data.id === action.payload.dataId) {
                return {
                  ...data,
                  color: action.payload.color
                };
              }
              return data;
            })
          };
        }
        return chart;
      });
    },
    // translation
    setTemplateLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
    onChangeWorksheetVideoTutorial: (state, action) => {
      state.worksheets = state.worksheets.map((ws: IWorksheet) => {
        if (ws.id === action.payload.id) {
          const url = { ...ws.video_tutorial };

          if (checkObjectHasEmptyValue(url)) {
            return {
              ...ws,
              video_tutorial: setFirstLanguage(url, action.payload.value)
            };
          }

          return {
            ...ws,
            video_tutorial: {
              ...ws.video_tutorial,
              [state.currentLanguage]: action.payload.value
            }
          };
        }
        return ws;
      });
    },
    // placeholder templates
    onSelectPlaceholder: (state, action) => {
      state.reportTemplatePlaceholderDetail = action.payload;
    }
  },
  extraReducers: {
    // template list
    [getTemplates.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getTemplates.fulfilled.toString()]: (
      state,
      action: PayloadAction<ITemplateResponse[]>
    ) => {
      state.loading = false;
      state.templates = [...action.payload];
    },
    [getTemplates.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // worksheets
    [createWorksheet.pending.toString()]: (state) => {
      state.loading = true;
    },
    [createWorksheet.fulfilled.toString()]: (
      state,
      action: PayloadAction<IWorksheet>
    ) => {
      state.loading = false;
      state.worksheets = [...state.worksheets, action.payload];
    },
    [createWorksheet.rejected.toString()]: (state) => {
      state.loading = false;
    },
    [editWorksheet.pending.toString()]: (state) => {
      state.loading = true;
    },
    [editWorksheet.fulfilled.toString()]: (
      state,
      action: PayloadAction<IWorksheet>
    ) => {
      state.loading = false;
      state.worksheets = state.worksheets.map((ws: IWorksheet) => {
        if (ws.id === action.payload.id) {
          return {
            ...ws,
            name: {
              ...ws.name,
              [state.currentLanguage]:
                action.payload.name[state.currentLanguage]
            },
            order_number: action.payload.order_number
          };
        }
        return ws;
      });
    },
    [editWorksheet.rejected.toString()]: (state) => {
      state.loading = false;
    },
    [deleteWorksheet.pending.toString()]: (state) => {
      state.loading = true;
    },
    [deleteWorksheet.fulfilled.toString()]: (
      state,
      action: PayloadAction<number>
    ) => {
      state.loading = false;
      state.worksheets = state.worksheets.filter(
        (ws: IWorksheet) => ws.id !== action.payload
      );
    },
    [deleteWorksheet.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // template detail
    [getTemplateDetail.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getTemplateDetail.fulfilled.toString()]: (
      state,
      action: PayloadAction<ITemplateResponse>
    ) => {
      const apiDataSourceRows = convertSubtemplatesToRows(
        action.payload.subtemplates,
        "api"
      );
      const noneDataSourceRows = convertSubtemplatesToRows(
        action.payload.subtemplates,
        "none"
      );

      state.loading = false;
      state.basicSettings.name = action.payload.name;
      state.basicSettings.type = action.payload.type;
      state.basicSettings.industries = action.payload.industries;
      state.basicSettings.published_from = action.payload.published_from;
      state.basicSettings.published_through = action.payload.published_through;
      state.basicSettings.customer_groups = action.payload.customer_groups;
      state.rows = [...apiDataSourceRows, ...noneDataSourceRows];
      state.worksheets = [...action.payload.worksheets].map((item) => ({
        ...item,
        name: setEnglishAsDefaultLanguage(item.name)
      }));
      state.ratioGroups = action.payload.ratio_groups.map(
        (group: IRatioGroup) => ({
          ...group,
          id: uuidv4(),
          ratioGroupId: Number(group.id),
          open: false,
          name: setEnglishAsDefaultLanguage(group.name),
          ratios: group.ratios.map((ratio) => ({
            ...ratio,
            title: setEnglishAsDefaultLanguage(ratio.title)
          }))
        })
      );
      state.charts = action.payload.charts
        .map((chart, index) => ({
          ...chart,
          id: uuidv4(),
          chartId: Number(chart.id),
          open: false,
          data_providers: chart.data_providers.map((data) => ({
            ...data,
            id: uuidv4()
          })),
          order_number: chart.order_number,
          title: setEnglishAsDefaultLanguage(chart.title)
        }))
        .sort((a, b) => a.order_number - b.order_number);
    },
    [getTemplateDetail.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // duplicate template
    [duplicateTemplate.pending.toString()]: (state) => {
      state.loading = true;
    },
    [duplicateTemplate.fulfilled.toString()]: (
      state,
      action: PayloadAction<ITemplateResponse>
    ) => {
      state.loading = false;
      state.templates = [...state.templates, action.payload];
    },
    [duplicateTemplate.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // get report templates
    [getReportTemplateCharts.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getReportTemplateCharts.fulfilled.toString()]: (
      state,
      action: PayloadAction<IReportTemplateChart[]>
    ) => {
      state.loading = false;
      state.reportTemplateCharts = action.payload;
    },
    [getReportTemplateCharts.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // get report templates ai contents
    [getReportTemplateAiContents.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getReportTemplateAiContents.fulfilled.toString()]: (
      state,
      action: PayloadAction<IReportTemplateAiContent>
    ) => {
      state.loading = false;
      state.reportTemplateAiContents = action.payload;
    },
    [getReportTemplateAiContents.rejected.toString()]: (state) => {
      state.loading = false;
    },
    // get report template placeholders
    [getReportTemplatePlaceholders.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getReportTemplatePlaceholders.fulfilled.toString()]: (
      state,
      action: PayloadAction<any[]>
    ) => {
      state.loading = false;
      state.reportTemplatePlaceholders = action.payload;
    },
    [getReportTemplatePlaceholders.rejected.toString()]: (state) => {
      state.loading = false;
    },
    // delete placeholder
    [deleteReportPlaceholder.pending.toString()]: (state) => {},
    [deleteReportPlaceholder.fulfilled.toString()]: (state) => {},
    [deleteReportPlaceholder.rejected.toString()]: (state) => {}
  }
});

export const {
  // rows
  onChangeName,
  onChangeType,
  onChangeIndustry,
  onChangeUserGroups,
  onChangeExpiredDate,
  onChangePublishedDate,
  addNewRow,
  deleteRow,
  duplicateRow,
  toggleCollapseRow,
  collapseAllRows,
  expandAllRows,
  onChangeRowName,
  onChangeRowSystemIndex,
  onChangeRowPeriod,
  onChangeRowInterval,
  onAddRowFormat,
  onToggleShowAssumption,

  // group logic
  addNewGroup,
  deleteGroup,
  onChangeDefaultGroup,
  onChangeGroupName,
  onChangeGroupExplanation,

  // row logic
  addLogicSet,
  deleteLogicSet,
  onchangeRowLogicPeriod,
  onchangeRowLogicCalculationType,
  onchangeRowLogicChildStatus,
  onChangeRowLogicFormula,
  insertRowToFormula,
  addRowLogicHelper,
  addRowLogicExistingHelper,
  deleteRowLogicHelper,
  onChangeRowLogicHelperTitle,
  onChangeRowLogicHelperCalculationType,
  onChangeRowLogicHelperFormula,
  onChangeHideDisplayParentField,
  onChangeRowLogicChildTitle,
  onChangeRowLogicChildCalculationType,
  onChangeRowLogicChildFormula,
  onAddChildRowFormat,
  onAddChildHelperFormat,
  onAddHelperFormat,
  addChildHelper,
  addExistingChildHelper,
  deleteChildHelper,
  onChangeChildHelperTitle,
  onChangeChildHelperCalculationType,
  onChangeChildHelperFormula,
  onChangeRowPlugRow,
  onChangePlugRowHistoricalLogic,
  onChangePlugRowProjectionLogic,
  getRowError,
  getGroupError,
  getLogicError,
  clearTemplateDetail,
  setNewRowOrder,
  addNewRatioGroup,
  deleteRatioGroup,
  addNewRatio,
  deleteRatio,
  toggleCollapseRatioGroup,
  collapseAllRatioGroups,
  expandAllRatioGroups,
  onChangeRatioGroupName,
  onChangeRatioSystemIndex,
  onChangeRatioTitle,
  onChangeRatioFormula,
  getChartRows,
  addNewChart,
  toggleCollapseChart,
  collapseAllCharts,
  expandAllCharts,
  deleteChart,
  onChangeChartTitle,
  onChangeChartPrimaryType,
  onChangeChartSecondaryType,
  onCheckChartDefault,
  addNewChartRow,
  deleteChartRow,
  onChangeChartRowData,
  onChangeSecondaryAxis,
  onChangeChartRowColor,
  getChartError,
  setTemplateLanguage,
  onChangeWorksheetVideoTutorial,
  onSelectPlaceholder,
  setChartOrder
} = templateSlice.actions;

export default templateSlice.reducer;
