import {
  IChatHistoryResponse,
  IFormatChatHistory
} from "types/ai-assistant.model";

export interface InitialState {
  isOpenChatBox: boolean;
  chatBoxState: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  currentValue: string;
  isLoadingHistory: boolean;
  chatHistory: IChatHistoryResponse;
  formattedChatHistory: IFormatChatHistory[];
}

export const initialState: InitialState = {
  isOpenChatBox: false,
  chatBoxState: {
    width: 660,
    height: 580,
    x: (window.innerWidth as any) - 680,
    y: 20
  },
  currentValue: "",
  isLoadingHistory: false,
  chatHistory: {
    count: 0,
    next: "",
    previous: "",
    results: []
  },
  formattedChatHistory: []
};
