import { ICheckoutRequest, IManageSubRequest } from "types/subscription.model";
import { authorizedRequest } from "./request";

const checkoutSubscription = (
  body: ICheckoutRequest
): Promise<{ redirect_url: string }> =>
  authorizedRequest.post("/api/payments/checkout/", { ...body });

const manageSubscription = (
  body: IManageSubRequest
): Promise<{ redirect_url: string }> =>
  authorizedRequest.post("/api/payments/manage/", { ...body });

const cancelSubscription = (): Promise<void> =>
  authorizedRequest.post("api/payments/cancel-subscription/");

const subscriptionServices = {
  checkoutSubscription,
  manageSubscription,
  cancelSubscription
};

export default subscriptionServices;
